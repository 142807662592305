import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get_wishlist, add_delete_wishlist_product } from "../../store/stash/api";
import { useNavigate } from 'react-router-dom';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import "./wishlist.css";
import { Link } from "react-router-dom";
import { get_product_details } from 'store/products/api';

const Wishlist = () => {
    const history = useNavigate();
    const dispatch = useDispatch();
    const state_account = useSelector(state => state.account);
    const state_stash = useSelector(state => state.stash);
    const [selectedItem, setSelectedItem] = useState(null);

    const getPriceHistory = (itemId) => {
        return [
            { date: '14 Oct', price: 900 },
            { date: '26 Oct', price: 900 },
            { date: '04 Nov', price: 850 },
            { date: '18 Nov', price: 880 },
            { date: '30 Nov', price: 900 },
            { date: '14 Dec', price: 900 },
            { date: '26 Dec', price: 799 }
        ];
    };

    const routetoproductdetails = (i) => {
        let payload = {
            'token': state_account.profile.token,
            'email': state_account.profile.email,
            "COO": state_account.profile.COO,
        };
        dispatch(get_product_details(payload, i, (res) => {
            if (res.data && res.data.length > 0) {
                window.open(res.data[0]?.channels[0].url, '_blank');
            }
        }));
    };

    useEffect(() => {
        let isMounted = true;
        if (isMounted)
            get_wishlists();
        return () => { isMounted = false };
    }, []);

    const remove_wishlist_product = (i) => {
        var userpayload = {
            "email": state_account.profile.email,
            "token": state_account.profile.token,
            "productid": i.item_id[0],
            "url": i.other_image_url[0]
        };
        dispatch(add_delete_wishlist_product(userpayload, (res) => {
            if (res.status === 200) {
                get_wishlists();
            }
        }));
    };

    const get_wishlists = () => {
        var userpayload = {
            "email": state_account.profile.email,
            "token": state_account.profile.token,
        };
        dispatch(get_wishlist(userpayload));
    };

    const togglePriceHistory = (itemId) => {
        setSelectedItem(selectedItem === itemId ? null : itemId);
    };

    // Add custom CSS to your stylesheet
    const styles = {
        priceHistorySection: {
            width: '100%',
            backgroundColor: '#f8f9fa',
            padding: '20px',
            marginTop: '10px',
            marginBottom: '20px',
            borderRadius: '8px',
            boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
        },
        header: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '15px'
        },
        title: {
            margin: 0,
            fontSize: '18px',
            fontWeight: '500'
        },
        lowestPrice: {
            color: '#4CAF50',
            fontWeight: '500',
            fontSize: '14px',
            backgroundColor: 'rgba(76, 175, 80, 0.1)',
            padding: '4px 8px',
            borderRadius: '4px'
        },
        chartContainer: {
            width: '100%',
            height: '300px',
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '8px'
        }
    };

    const WishlistItem = ({ item, index }) => {
        // Add function to calculate Y-axis range and ticks
        const calculateYAxisConfig = (priceHistory) => {
            if (!priceHistory || priceHistory.length === 0) return { domain: [0, 1000], ticks: [0, 200, 400, 600, 800, 1000] };

            // Get min and max prices
            const prices = priceHistory.map(p => p.amount);
            const minPrice = Math.min(...prices);
            const maxPrice = Math.max(...prices);

            // Calculate range with buffer
            const range = maxPrice - minPrice;
            const buffer = Math.max(2000, range * 0.5); // At least 2000 or 50% of range
            
            const lowerBound = Math.max(0, Math.floor((minPrice - buffer) / 100) * 100);
            const upperBound = Math.ceil((maxPrice + buffer) / 100) * 100;

            // Generate ticks
            const tickCount = 8; // Number of ticks we want
            const tickInterval = Math.ceil((upperBound - lowerBound) / (tickCount - 1) / 100) * 100;
            const ticks = Array.from({ length: tickCount }, (_, i) => lowerBound + (i * tickInterval));

            return {
                domain: [lowerBound, upperBound],
                ticks: ticks
            };
        };

        const yAxisConfig = calculateYAxisConfig(item?.priceHistory);

        return (
            <>
                <div className="mywishlist_area" key={index}>
                    <div className="display_flex space_between2 align_item_center">
                        <div className="display_flex align_item_center">
                            <div className="mywishlist_img" onClick={() => routetoproductdetails(item.item_id[0])}>
                                {(item.other_image_url[0] === "" || item.other_image_url[0] === ".jpg") ?
                                    <img src="/images/defultimg.svg" style={{ "cursor": "pointer" }} alt="" className="img-responsive" /> :
                                    <img src={`${item.other_image_url[0]}`} alt="" className="img-responsive" />
                                }
                            </div>
                            <div className="mywishlist_content">
                                <p className="mywishheading" onClick={() => routetoproductdetails(item.item_id[0])}>
                                    {item.item_name[0]}
                                </p>
                                <button
                                    className="price-history-btn"
                                    onClick={() => togglePriceHistory(item.item_id[0])}
                                    style={{
                                        border: 'none',
                                        background: 'none',
                                        color: '#0066cc',
                                        cursor: 'pointer',
                                        padding: '5px 0',
                                        fontSize: '14px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '5px'
                                    }}
                                >
                                    Price History
                                    <span style={{ fontSize: '12px' }}>
                                        {selectedItem === item.item_id[0] ? '▼' : '▶'}
                                    </span>
                                </button>
                            </div>
                        </div>
                        <p className="delete_icon" data-toggle="modal" data-target={`#${index}`}>
                            <i className="fa-solid fa-trash"></i>
                        </p>
                        <div className="modal fade" id={`${index}`} tabIndex="10" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="feed_details modal-dialog offer_dialog modal-md">
                                <div className="modal-body">
                                    <div className="friends-timeline-pop-up">
                                        <div className="stash-pop-up-section">
                                            <div className="stash-pop-up-box">
                                                <div className="wishlist-Just-checking-section">
                                                    <h6>DELETE </h6>
                                                </div>
                                                <div className="delete-section">
                                                    <h6>Are you sure you want to delete?</h6>
                                                </div>
                                                <div className="checking-row">
                                                    <button className="checkingforyes-btn-default" onClick={() => remove_wishlist_product(item)} data-dismiss="modal">YES</button>
                                                    <button className="checking-btn-default" data-dismiss="modal">NO</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {selectedItem === item.item_id[0] && (
                    <div style={styles.priceHistorySection}>
                        <div style={styles.header}>
                            <h3 style={styles.title}>
                                Price History
                            </h3>
                            <span style={styles.lowestPrice}>
                                Current Price: ₹{item?.priceHistory[item?.priceHistory.length - 1]?.amount}
                            </span>
                        </div>

                        <div style={styles.chartContainer}>
                            <ResponsiveContainer width="100%" height="100%">
                                <LineChart
                                    data={item?.priceHistory}
                                    margin={{ top: 10, right: 30, left: 20, bottom: 20 }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
                                    <XAxis
                                        dataKey="date"
                                        tick={{ fontSize: 12 }}
                                        stroke="#666"
                                        tickFormatter={(date) => new Date(date).toISOString().split('T')[0]}
                                    />
                                    <YAxis
                                        domain={yAxisConfig.domain}
                                        ticks={yAxisConfig.ticks}
                                        tick={{ fontSize: 12 }}
                                        stroke="#666"
                                        tickFormatter={(value) => `₹${value}`}
                                    />
                                    <Tooltip
                                        formatter={(value) => [`₹${value}`, 'Price']}
                                        contentStyle={{
                                            backgroundColor: '#fff',
                                            border: 'none',
                                            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                                            borderRadius: '4px'
                                        }}
                                        labelFormatter={(date) => `Date: ${new Date(date).toISOString().split('T')[0]}`}
                                    />
                                    <Line
                                        type="monotone"
                                        dataKey="amount"
                                        stroke="#ff4c4c"
                                        strokeWidth={2}
                                        dot={{ fill: '#ff4c4c', r: 4 }}
                                        activeDot={{ r: 6 }}
                                    />
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                )}
            </>
        );
    };

    return (
        <div className="classic_container">
            <div className="classic_height">
                {state_stash.wishlist.length === 0 ? (
                    <>
                        <div className="wishlist-empty-img">
                            <img src="/images/wishlist.svg" alt="" />
                        </div>
                        <div className="wishlist-empty-heading-section">
                            <p>Your wishlist is empty</p>
                        </div>
                        <div className="explore-btn">
                            <Link to={`/shop`} className="save_btn ui-link">Explore Products</Link>
                        </div>
                    </>
                ) : (
                    state_stash.wishlist
                        .filter(i => i.item_name[0] != undefined)
                        .map((item, index) => (
                            <WishlistItem key={index} item={item} index={index} />
                        ))
                )}
            </div>
        </div>
    );
};

export default Wishlist;