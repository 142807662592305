/* eslint-disable */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { delete_single_look } from "../../../store/stash/api"
import { useNavigate } from 'react-router-dom';
import MyLookShare from "components/mylookshare";

const MyLookCard = ({ i, onDelete }) => {
    const history = useNavigate();
    const dispatch = useDispatch();
    const state_account = useSelector(state => state.account);
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);

    const edit_look_route = (i) => {
        history('/editlook/' + i.bundleid, { state: {bundledetails:i} });
    }
    
    const delete_look = () => {
        var userpayload = {
            "email": state_account.profile.email,
            "token": state_account.profile.token,
            "bundleid": i.bundleid
        }
        dispatch(delete_single_look(userpayload, (res) => {
            if (res.status === 200) {
                if (onDelete) {
                    onDelete(i.bundleid);
                } else {
                    window.location.reload();
                }
                closeDeleteModal();
            }
        }));
    }

    const openDeleteModal = () => {
        setShowDeleteModal(true);
    };

    const closeDeleteModal = () => {
        setShowDeleteModal(false);
    };

    // Common delete modal component that will be rendered at the bottom
    const DeleteModal = () => (
        <div className={`modal ${showDeleteModal ? 'show d-block' : ''}`} 
            tabIndex="10" 
            role="dialog"
            aria-labelledby="exampleModalLabel" 
            aria-hidden="true"
            style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
            <div className="feed_details modal-dialog offer_dialog modal-md" style={{ zIndex: 1050 }}>
                <div className="">
                    <div className="modal-body">
                        <div className="friends-timeline-pop-up">
                            <div className="stash-pop-up-section">
                                <div className="stash-pop-up-box">
                                    <div className="wishlist-Just-checking-section">
                                        <h6>DELETE </h6>
                                    </div>
                                    <div className="delete-section">
                                        <h6>Are you sure you want to delete?</h6>
                                    </div>
                                    <div className="checking-row">
                                        <button className="checkingforyes-btn-default" onClick={delete_look}>YES</button>
                                        <button className="checking-btn-default" onClick={closeDeleteModal}>NO</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <>
            {i.layout === "2-2-1" ? (
                <div className="classic_formate" style={{ backgroundImage: i.backgroundimage === "" || i.backgroundimage === undefined ? "linear-gradient(179deg, #6398AF, #FEAB79)" : `url(${i.backgroundimage})` }}>
                    <p className="formal_btn"><a>{i.bundlename}</a></p>
                    <div className="classic_right_icon">
                        <ul className="list-unstyled" style={{ "zIndex": "5" }}>
                            <li><a href="#" data-toggle="modal" data-target={`#${i._id}sharelook`}><i className="fa-solid fa-share-nodes"></i></a></li>
                            <li><a onClick={() => edit_look_route(i)}><i className="fa-solid fa-pen"></i></a></li>
                            <li><a onClick={openDeleteModal} style={{ cursor: 'pointer' }}><i className="fa-solid fa-trash"></i></a></li>
                        </ul>
                    </div>
                    <div className="classic_formate_scroll1_padding classic_formate_scroll1 no_scroll">
                        <div className="row-section">
                            <div className="new-look-row-for-selected-look">
                                <div className="classic_img_layout3 classic-img-createnewlook-area classic-img-area7">
                                    <img src={`${i.productdetails[0].productimage}`} alt=""
                                        className="classic_img" />
                                </div>
                                <div className="classic_img_layout3 classic-img-createnewlook-area classic-img-area7">
                                    <img src={`${i.productdetails[1].productimage}`} alt=""
                                        className="classic_img" />
                                </div>
                                <div className="classic_img_layout3 classic-img-createnewlook-area classic-img-area7">
                                    <img src={`${i.productdetails[2].productimage}`} alt=""
                                        className="classic_img" />
                                </div>
                            </div>
                        </div>
                        <div className="space_evenly display_flex">
                            <div className="classic_img_layout3 classic-img-createnewlook-area classic-img-area6">
                                <img src={`${i.productdetails[3].productimage}`} alt=""
                                    className="classic_img" />
                            </div>
                            <div className="classic_img_layout3 classic-img-createnewlook-area classic-img-area6">
                                <img src={`${i.productdetails[4].productimage}`} alt=""
                                    className="classic_img" />
                            </div>
                        </div>
                    </div>
                </div>
            ) : i.layout === "3-2" ? (
                <div className="classic_formate" style={{ backgroundImage: i.backgroundimage === "" || i.backgroundimage === undefined ? "linear-gradient(179deg, #6398AF, #FEAB79)" : `url(${i.backgroundimage})` }}>
                    <p className="formal_btn"><a>{i.bundlename}</a></p>
                    <div className="classic_right_icon">
                        <ul className="list-unstyled">
                            <li><a href="#" data-toggle="modal" data-target={`#${i._id}sharelook`}><i className="fa-solid fa-share-nodes"></i></a></li>
                            <li><a onClick={() => edit_look_route(i)}><i className="fa-solid fa-pen"></i></a></li>
                            <li><a onClick={openDeleteModal} style={{ cursor: 'pointer' }}><i className="fa-solid fa-trash"></i></a></li>
                        </ul>
                    </div>
                    <div className="classic_formate_scroll1 no_scroll">
                        <div className="align_baseline row_section_layout2 mylook_img_layout classic_img1 new-look-box-section">
                            <div>
                                <div className="classic_img_layout3 classic-img-createnewlook-area classic-img-area6">
                                    <img src={`${i?.productdetails[3]?.productimage}`} alt=""
                                        className="classic_img" />
                                </div>
                                <div className="classic_img_layout3 classic-img-createnewlook-area classic-img-area6">
                                    <img src={`${i?.productdetails[4]?.productimage}`} alt=""
                                        className="classic_img" />
                                </div>
                                <div className="classic_img_layout3 classic-img-createnewlook-area classic-img-area6">
                                    <img src={`${i?.productdetails[5]?.productimage}`} alt=""
                                        className="classic_img" />
                                </div>
                            </div>
                            <div className="row-section">
                                <div className="classic_section_box2">
                                    <div className="classic_img_layout3 classic-img-createnewlook-area classic-img-area7">
                                        <img src={`${i?.productdetails[0]?.productimage}`} alt=""
                                            className="classic_img" />
                                    </div>
                                    <div className="classic_img_layout3 classic-img-createnewlook-area classic-img-area7">
                                        <img src={`${i?.productdetails[1]?.productimage}`} alt=""
                                            className="classic_img" />
                                    </div>
                                    <div className="classic_img_layout3 classic-img-createnewlook-area classic-img-area7">
                                        <img src={`${i?.productdetails[2]?.productimage}`} alt=""
                                            className="classic_img" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : i.layout === "4-4" || i.layout === "" ? (
                <div className="classic_formate" style={{ backgroundImage: i.backgroundimage === "" || i.backgroundimage === undefined ? "linear-gradient(179deg, #6398AF, #FEAB79)" : `url(${i.backgroundimage})` }} >
                    <p className="formal_btn"><a>{i.bundlename}</a></p>
                    <div className="classic_right_icon">
                        <ul className="list-unstyled">
                            <li><a href="#" data-toggle="modal" data-target={`#${i._id}sharelook`}><i className="fa-solid fa-share-nodes"></i></a></li>
                            <li><a onClick={() => edit_look_route(i)}><i className="fa-solid fa-pen"></i></a></li>
                            <li><a onClick={openDeleteModal} style={{ cursor: 'pointer' }}><i className="fa-solid fa-trash"></i></a></li>
                        </ul>
                    </div>
                    <div className="classic_formate_padding no_scroll">
                        <div className="classic_img1 new-look-row-for-selected-look">
                            {i.productdetails.length === 0 ? <></> :
                                <div className="classic_img1 new-look-row-for-selected-look">
                                    <div className="select_layout_img1 classic-img-area6">
                                        <img src={`${i?.productdetails[0]?.productimage}`} alt=""
                                            className="classic_img1 classic_img" />
                                    </div>
                                    <div className="select_layout_img1 classic-img-area6">
                                        <img src={`${i?.productdetails[1]?.productimage}`} alt=""
                                            className="classic_img1 classic_img" />
                                    </div>
                                    <div className="select_layout_img1 classic-img-area6">
                                        <img src={`${i?.productdetails[2]?.productimage}`} alt=""
                                            className="classic_img1 classic_img" />
                                    </div>
                                    <div className="select_layout_img1 classic-img-area6">
                                        <img src={`${i?.productdetails[3]?.productimage}`} alt=""
                                            className="classic_img1 classic_img" />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            ) : <></>}
            <MyLookShare lookdata={i} modalid={`${i._id}sharelook`} />
            <DeleteModal />
        </>
    );
}

export default MyLookCard;

