/* eslint-disable */
import React, { useEffect, useState } from "react";
import { get_friend_list } from "../../store/friend/api"
import { Footer, Header, Reshare, Productshare } from "components";
import { get_categroylist_pagination, get_all_brandlist_pagination } from "../../store/category/api"
import { useDispatch, useSelector } from "react-redux";
import { jesssu_get_all_shares, jesssu_get_comments_for_single_post, jesssu_get_comments_for_sharefeed } from 'store/social/api';
import { get_product_newarrivals, get_selected_brand_category_product, get_user_visited_product, get_product_details, get_AI_Recommended_Product } from 'store/products/api';
import { Link } from "react-router-dom";
// import ScriptTag from 'react-script-tag';
import $ from "jquery";
import { jesssu_get_shared_like, jesssu_get_shared_dislike } from 'store/social/api';
import { get_wishlist, add_delete_wishlist_product } from 'store/stash/api';
import ReactTooltip from "react-tooltip";
import { notification_message_status } from "../../store/account/accountActions"
import { useNavigate } from 'react-router';
import { get_all_share_shop, get_all_categories_shop, get_all_brands_shop } from '../../store/social/socialActions'
import { get_friend_list_success } from '../../store/account/accountActions'
import { get_user_profile_details, get_banner } from "../../store/account/api"
import { user_signin } from 'store/account/accountActions';
import { Helmet } from 'react-helmet';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { AIAgent } from 'genai-product-comparison-agent';
import { useMediaQuery } from 'react-responsive';
// import { useTimer } from "react-timer-hook";
const Shop = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    // const productListRef = useRef(null);
    // const [suggestedview, setsuggestedview] = useState(false)
    const history = useNavigate();
    const dispatch = useDispatch();
    const state_account = useSelector(state => state.account);
    const state_social = useSelector(state => state.social);
    // const allsocial = state_social.allsocialshop;
    const allcategories = state_social.allcategories;
    const allbrands = state_social.allbrands;
    // const [categorylist, setCategoryList] = useState([]);
    const [wishlist, setWishlist] = useState([]);
    const [allsharelist, setAllsharelist] = useState([]);
    const [newarivallist, setNewarivallist] = useState([]);
    const [recentlyVisitedProductlist, setRecentlyVisitedProductlist] = useState([]);
    const [suggestedlist, setSuggestedlist] = useState([]);
    // const [postmessage, setpostmessage] = useState("")
    // const [suggesteddetaillist, setSuggesteddetaillist] = useState([]);
    // const [selectedBrands, setSelectedBrands] = useState([]);
    const [comment, setcomment] = useState("");
    const [postcomment, setpostcomment] = useState([]);
    const offset = 0;
    const count = 50;
    const userfirstname = state_account.profile.firstname;
    const [singlepost, setsinglepost] = useState(typeof any)
    // const [brands, setBrands] = useState([])
    const [showNotification, setShowNotification] = useState(false);
    const [showNotificationMessage, setShowNotificationMessage] = useState(false);
    // const [yourbrands, setYourBrands] = useState([]);
    const useremail = state_account.profile.email;
    const usertoken = state_account.profile.token;
    //For reshare popup
    const [shareddata, setshareddata] = useState(typeof any)
    // const [sharetype, setsharetype] = useState("Post")
    const [reshare, setreshare] = useState(false)
    const [productshare, setproductshare] = useState(false)
    // const [carousel, setcarousel] = useState(true)

    const [bannerList, setBannerList] = useState([])
    const [newArrivalIndex, setnewArrivalIndex] = useState(0)
    const [recentlyVisitedIndex, setRecentlyVisitedIndex] = useState(0)
    const [selectedProductIndex, setselectedProductIndex] = useState(0)
    const [categoryIndex, setcategoryIndex] = useState(0)
    const [shareIndex, setshareIndex] = useState(0)
    const [brandIndex, setbrandIndex] = useState(0)
    const [personalbrandIndex, setpersonalbrandIndex] = useState(0)
    const [view, setview] = useState(false)
    const [aiRecomendedTypes, setAIRecomendedTypes] = useState(["Product Share", "View Product", "Comment On Product", "Like Product"])
    const [recommendedShareProductList, setrecommendedShareProductList] = useState([]);
    const [recommendedLikeProductList, setrecommendedLikeProductList] = useState([]);
    const [recommendedCommentProductList, setrecommendedCommentProductList] = useState([]);
    const [recommendedViewProductList, setrecommendedViewProductList] = useState([]);
    const [recommendedShareProductIndex, setrecommendedShareProductIndex] = useState(0)
    const [recommendedLikeProductIndex, setrecommendedLikeProductIndex] = useState(0)
    const [recommendedCommentProductIndex, setrecommendedCommentProductIndex] = useState(0)
    const [recommendedViewProductIndex, setrecommendedViewProductIndex] = useState(0)

    let user = null
    useEffect(() => {
        if (localStorage.getItem('user-info')) {
            user = JSON.parse(localStorage.getItem('user-info'));

            if (user === null) {
                history("/login")
            } else {
                history("/shop")
            }
        }
        let isMounted = true;
        if (isMounted) {
            get_banners_for_shop();
            get_profile_details();
            get_categories();
            get_brands();
            get_allshare();
            get_newarrival();
            get_wishlists();
            // setYourBrands();
            getsuggestedproduct();
            getfriendlist();
            get_recently_visited_products();
            get_like_recommended_product();
            get_view_recommended_product();
            get_comment_recommended_product();
            get_share_recommended_product();
            // setYourBrands(state_account.profile.branddata);
            $(window).bind('scroll', function () {
                var navHeight = $(window).height() - 400;
                if ($(window).scrollTop() > navHeight) {
                    $('.navigation_menu').addClass('fixed2');
                }
                else {
                    $('.navigation_menu').removeClass('fixed2');
                }
            });

        }
        setview(true)
        return () => { isMounted = false };
    }, []);

    const AllSocialExpireData = ({ item, onExpirationChange }) => {

        const expiryTime = new Date(item.expiresAt);
        const [timeLeft, setTimeLeft] = useState(expiryTime - new Date());

        useEffect(() => {
            const intervalId = setInterval(() => {
                const newTimeLeft = expiryTime - new Date();
                if (newTimeLeft <= 0) {
                    // Timer has expired
                    clearInterval(intervalId);
                    setTimeLeft(0);
                } else {
                    setTimeLeft(newTimeLeft);
                }
            }, 1000);

            return () => {
                clearInterval(intervalId);
            };
        }, [expiryTime]);

        const minutes = Math.floor(timeLeft / 60000);
        const seconds = Math.floor((timeLeft % 60000) / 1000);

        return (
            <div className="timeline_wrapp_box">
                {timeLeft <= 0 ? (
                    <h5>Expired</h5>
                ) : (
                    <span>{String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}</span>
                )}
            </div>
        );
    };

    const handleExpirationChangeallSocial = (itemId, isExpired) => {
        setAllsharelist((prevSocial) =>
            prevSocial.map((data) =>
                data._id === itemId ? { ...data, expiresStatus: isExpired } : data
            )
        );
    };

    const handlePrevClick = (type) => {
        if (type === "NewArrivals") {
            setnewArrivalIndex(newArrivalIndex - 1);
        }
        else if (type === "SelectedProduct") {
            setselectedProductIndex(selectedProductIndex - 1);
        }
        else if (type === "Category") {
            setcategoryIndex(categoryIndex - 1);
        } else if (type === "Share") {
            setshareIndex(shareIndex - 1);
        }
        else if (type === "Brand") {
            setbrandIndex(brandIndex - 1);
        }
        else if (type === "PersonalBrand") {
            setpersonalbrandIndex(personalbrandIndex - 1);
        }
        else if (type === "RecentlyVisited") {
            setRecentlyVisitedIndex(recentlyVisitedIndex - 1);
        } else if (type === "RecommendedViewProduct") {
            if (recommendedViewProductIndex !== 0) {
                setrecommendedViewProductIndex(recommendedViewProductIndex - 1);
            }
        }
        else if (type === "RecommendedShareProduct") {
            if (recommendedShareProductIndex !== 0) {
                setrecommendedShareProductIndex(recommendedShareProductIndex - 1);
            }
        }
        else if (type === "RecommendedLikeProduct") {
            if (recommendedLikeProductIndex !== 0) {
                setrecommendedLikeProductIndex(recommendedLikeProductIndex - 1);
            }
        }
        else if (type === "RecommendedCommentProduct") {
            if (recommendedCommentProductIndex !== 0) {
                setrecommendedCommentProductIndex(recommendedCommentProductIndex - 1);
            }
        }
    };


    const handleNextClick = (type) => {
        if (type === "NewArrivals") {
            if (newArrivalIndex + 4 < newarivallist.length) {
                setnewArrivalIndex(newArrivalIndex + 1);
            }
        }
        else if (type === "SelectedProduct") {
            if (selectedProductIndex + 4 < suggestedlist.length) {
                setselectedProductIndex(selectedProductIndex + 1);
            }
        } else if (type === "Category") {
            if (categoryIndex + 6 < allcategories.length) {
                setcategoryIndex(categoryIndex + 1);
            }
        }
        else if (type === "Share") {
            if (shareIndex + 4 < allsharelist.length) {
                setshareIndex(shareIndex + 1);
            }
        } else if (type === "Brand") {
            if (brandIndex + 6 < allbrands.length) {
                setbrandIndex(brandIndex + 1);
            }
        }
        else if (type === "PersonalBrand") {
            if (personalbrandIndex + 6 < state_account.profile.branddata.length) {
                setpersonalbrandIndex(personalbrandIndex + 1);
            }
            // setpersonalbrandIndex(personalbrandIndex + 1);
        }
        else if (type === "RecentlyVisited") {
            if (recentlyVisitedIndex + 4 < recentlyVisitedProductlist.length) {
                setRecentlyVisitedIndex(recentlyVisitedIndex + 1);
            }
        }
        else if (type === "RecommendedViewProduct") {
            if (recommendedViewProductIndex + 4 < recommendedViewProductList.length) {
                setrecommendedViewProductIndex(recommendedViewProductIndex + 1);
            }
        }
        else if (type === "RecommendedShareProduct") {
            if (recommendedShareProductIndex + 4 < recommendedShareProductList.length) {
                setrecommendedShareProductIndex(recommendedShareProductIndex + 1);
            }
        }
        else if (type === "RecommendedLikeProduct") {
            if (recommendedLikeProductIndex + 4 < recommendedLikeProductList.length) {
                setrecommendedLikeProductIndex(recommendedLikeProductIndex + 1);
            }
        }
        else if (type === "RecommendedCommentProduct") {
            if (recommendedCommentProductIndex + 4 < recommendedCommentProductList.length) {
                setrecommendedCommentProductIndex(recommendedCommentProductIndex + 1);
            }
        }
    };
    const handleScroll = (e, type) => {
        if (e.deltaX > 0) {
            if (type === "NewArrivals") {
                if (newArrivalIndex + 4 < suggestedlist.length) {
                    setnewArrivalIndex(newArrivalIndex + 1);
                }
            }
            else if (type === "SelectedProduct") {
                if (selectedProductIndex + 4 < suggestedlist.length) {
                    setselectedProductIndex(selectedProductIndex + 1);
                }
            }
            else if (type === "Category") {
                if (categoryIndex + 6 < allcategories.length) {
                    setcategoryIndex(categoryIndex + 1);
                }
            }
            else if (type === "Share") {
                if (shareIndex + 4 < allsharelist.length) {
                    setshareIndex(shareIndex + 1);
                }
            }
            else if (type === "Brand") {
                if (brandIndex + 6 < allbrands.length) {
                    setbrandIndex(brandIndex + 1);
                }
            }
            else if (type === "PersonalBrand") {
                if (personalbrandIndex + 6 < state_account.profile.branddata.length) {
                    setpersonalbrandIndex(personalbrandIndex + 1);
                }
            }
            else if (type === "RecentlyVisited") {
                if (recentlyVisitedIndex + 4 < recentlyVisitedProductlist.length) {
                    setRecentlyVisitedIndex(recentlyVisitedIndex + 1);
                }
            }
            else if (type === "RecommendedViewProduct") {
                if (recommendedViewProductIndex + 4 < recommendedViewProductList.length) {
                    setrecommendedViewProductIndex(recommendedViewProductIndex + 1);
                }
            }
            else if (type === "RecommendedShareProduct") {
                if (recommendedShareProductIndex + 4 < recommendedShareProductList.length) {
                    setrecommendedShareProductIndex(recommendedShareProductIndex + 1);
                }
            }
            else if (type === "RecommendedLikeProduct") {
                if (recommendedLikeProductIndex + 4 < recommendedLikeProductList.length) {
                    setrecommendedLikeProductIndex(recommendedLikeProductIndex + 1);
                }
            }
            else if (type === "RecommendedCommentProduct") {
                if (recommendedCommentProductIndex + 4 < recommendedCommentProductList.length) {
                    setrecommendedCommentProductIndex(recommendedCommentProductIndex + 1);
                }
            }

        } else {

            if (type === "NewArrivals") {
                if (newArrivalIndex !== 0) {
                    setnewArrivalIndex(newArrivalIndex - 1);
                }
            }
            else if (type === "SelectedProduct") {
                if (selectedProductIndex !== 0) {
                    setselectedProductIndex(selectedProductIndex - 1);
                }
            } else if (type === "Category") {
                if (categoryIndex !== 0) {
                    setcategoryIndex(categoryIndex - 1);;
                }
            }
            else if (type === "Share") {
                if (shareIndex !== 0) {
                    setshareIndex(shareIndex - 1);;
                }
            }
            else if (type === "Brand") {
                if (brandIndex !== 0) {
                    setbrandIndex(brandIndex - 1);
                }
            }
            else if (type === "PersonalBrand") {
                if (personalbrandIndex !== 0) {
                    setpersonalbrandIndex(personalbrandIndex - 1);
                }
            }
            else if (type === "RecentlyVisited") {
                if (recentlyVisitedIndex !== 0) {
                    setRecentlyVisitedIndex(recentlyVisitedIndex - 1);
                }
            }
            else if (type === "RecommendedViewProduct") {
                if (recommendedViewProductIndex !== 0) {
                    setrecommendedViewProductIndex(recommendedViewProductIndex - 1);
                }
            }
            else if (type === "RecommendedShareProduct") {
                if (recommendedShareProductIndex !== 0) {
                    setrecommendedShareProductIndex(recommendedShareProductIndex - 1);
                }
            }
            else if (type === "RecommendedLikeProduct") {
                if (recommendedLikeProductIndex !== 0) {
                    setrecommendedLikeProductIndex(recommendedLikeProductIndex - 1);
                }
            }
            else if (type === "RecommendedCommentProduct") {
                if (recommendedCommentProductIndex !== 0) {
                    setrecommendedCommentProductIndex(recommendedCommentProductIndex - 1);
                }
            }
        }
    };

    function get_recently_visited_products() {
        var userpayload =
        {
            "email": state_account.profile.email,
            "token": state_account.profile.token
        }

        dispatch(get_user_visited_product(userpayload, 0, 50, (res => {
            if (res.status === 200) {
                setRecentlyVisitedProductlist(res.data)
            }
        })));
    }

    function get_like_recommended_product() {
        var payload =
        {
            "email": state_account.profile.email,
            "token": state_account.profile.token,
            "type": aiRecomendedTypes[3],
            "pageno": offset,
            "count": count
        }

        dispatch(get_AI_Recommended_Product(payload, (res => {
            if (res.status === 200) {
                setrecommendedLikeProductList(res.data)
            }
        })));
    }
    function get_view_recommended_product() {
        var payload =
        {
            "email": state_account.profile.email,
            "token": state_account.profile.token,
            "type": aiRecomendedTypes[1],
            "pageno": offset,
            "count": count
        }

        dispatch(get_AI_Recommended_Product(payload, (res => {
            if (res.status === 200) {
                setrecommendedViewProductList(res.data)
            }
        })));
    }
    function get_comment_recommended_product() {
        var payload =
        {
            "email": state_account.profile.email,
            "token": state_account.profile.token,
            "type": aiRecomendedTypes[2],
            "pageno": offset,
            "count": count
        }

        dispatch(get_AI_Recommended_Product(payload, (res => {
            if (res.status === 200) {
                setrecommendedCommentProductList(res.data)
            }
        })));
    }
    function get_share_recommended_product() {
        var payload =
        {
            "email": state_account.profile.email,
            "token": state_account.profile.token,
            "type": aiRecomendedTypes[0],
            "pageno": offset,
            "count": count
        }

        dispatch(get_AI_Recommended_Product(payload, (res => {
            if (res.status === 200) {
                setrecommendedShareProductList(res.data)
            }
        })));
    }



    function getsuggestedproduct() {
        const payload = {
            categories: state_account.profile.category,
            brands: state_account.profile.brand,
            COO: state_account.profile.COO,
            gender: state_account.profile.gender
        }
        dispatch(get_selected_brand_category_product(payload, (res) => {
            if (res.status === 200) {

                setSuggestedlist(res.data)
                // setsuggestedview(true)
            }
        }))
    }

    function gotosuggestedproductlist() {
        let category = ""
        state_account.profile.category.forEach(i => {
            const data = changevalueforurl(i)
            category = category + "&category=" + data
        })
        // console.log(category)
        let brand = ''
        state_account.profile.brand.forEach(i => {
            const data = changevalueforurl(i)
            brand = brand + "&brand=" + data
        })
        // console.log(brand)
        history(`/products/any${category}${brand}`)
    }

    // Reshare popup functionalities
    function getresharedata(data) {
        setshareddata(data)
        setreshare(true)
    }
    function getproductdataforshare(data) {
        setshareddata(data)
        setproductshare(true)
    }
    //Previous functions
    function get_wishlists() {
        var userpayload =
        {
            "email": state_account.profile.email,
            "token": state_account.profile.token
        }
        dispatch(get_wishlist(userpayload, (res) => {

            if (res.status === 200) {
                const wishlistItems = res.data.map(item => item.item_id[0]);
                setWishlist(current => [...current, ...wishlistItems]);
            }
        }));

    }
    const getfriendlist = () => {
        let userpayload =
        {
            "useremail": state_account.profile.email,
            "token": state_account.profile.token,
        }
        dispatch(get_friend_list(userpayload, (res) => {
            if (res.status === 200) {
                // setFriendList(res.data);
                dispatch(get_friend_list_success(res.data))
            }
        }));
    }
    async function addproductinwishlist(data) {
        let payload = {
            "email": state_account.profile.email,
            "token": state_account.profile.token,
            "productid": data.SKU,
            "url": data.MainImage
        }

        dispatch(add_delete_wishlist_product(payload, (res) => {
            if (res.status === 200) {
                if (res.data === "Product added to wishist") {
                    setShowNotification(true)
                    setShowNotificationMessage("Product added to your wishlist sucessfully!")
                    setWishlist(current => [...current, data.SKU])
                    closenotification()
                }
                else {
                    setShowNotification(true)
                    setShowNotificationMessage("Product removed from your wishlist sucessfully!")
                    setWishlist((prevState) =>
                        prevState.filter((prevItem) => prevItem !== data.SKU)
                    );
                    closenotification()
                }
            }
        }));
    }

    function closenotification() {
        setTimeout(function () {
            setShowNotification(false);
            dispatch(notification_message_status(false))
        }, 5000);
    }
    function get_banners_for_shop() {
        var payload =
        {
            "gender": state_account.profile.gender,
        }
        dispatch(get_banner(payload, (res) => {
            if (res.status === 200) {
                setBannerList(res.data)
            }
        }));
    }

    function get_categories() {

        var userpayload =
        {
            "gender": state_account.profile.gender,
            "COO": state_account.profile.COO
        }
        dispatch(get_categroylist_pagination(userpayload, offset, count, (res) => {
            if (res.status === 200) {
                // setCategoryList(res.data);
                dispatch(get_all_categories_shop(res.data));
            }
        }));
    }
    function get_allshare() {
        var userpayload =
        {
            "email": state_account.profile.email,
            "token": state_account.profile.token
        }

        dispatch(jesssu_get_all_shares(userpayload, 0, 50, (res => {
            if (res.status === 200) {
                const updatedShares = res.data.map(i => {
                    const isliked = i.likeby[0].includes(userfirstname);
                    const isdisliked = i.dislikeby[0].includes(userfirstname);
                    const isshared = i.sharedby.includes(userfirstname);

                    return {
                        ...i,
                        isliked,
                        isdisliked,
                        isshared
                    };
                });

                setAllsharelist(current => [...current, ...updatedShares]);
                dispatch(get_all_share_shop(updatedShares));
            }
        })));
    }
    function get_newarrival() {
        var userpayload =
        {
            "gender": state_account.profile.gender,
            "COO": state_account.profile.COO,
            "sortby": ""
        }

        dispatch(get_product_newarrivals(userpayload, 0, 50, (res => {
            if (res.status === 200) {
                setNewarivallist(res.data)
            }
        })));
    }
    function get_brands() {

        var userpayload =
        {
            "gender": state_account.profile.gender,
            "COO": state_account.profile.COO
        }
        dispatch(get_all_brandlist_pagination(userpayload, 0, 50, (res => {
            if (res.status === 200) {
                // setBrands(res.data)
                dispatch(get_all_brands_shop(res.data))
            }
        })));
    }
    async function likeapost(count, shareid) {
        var payload =
        {
            'email': useremail,
            'id': shareid,
            'token': usertoken
        }
        dispatch(jesssu_get_shared_like(payload, (res => {
            if (res.status === 200) {
                if (allsharelist[count].isliked === false) {
                    if (allsharelist[count].isdisliked === true) {
                        const newStateforlike = allsharelist.map((i = typeof any, index) => {
                            if (index === count) {
                                return { ...i, Numberoflikes: parseInt(`${allsharelist[count].Numberoflikes}`) + 1, isliked: true, Numberofdislikes: parseInt(`${allsharelist[count].Numberofdislikes}`) - 1, isdisliked: false };
                            }
                            return i;
                        });
                        setAllsharelist(newStateforlike);
                    }
                    else {
                        const newStateforlike = allsharelist.map((i = typeof any, index) => {
                            if (index === count) {
                                return { ...i, Numberoflikes: parseInt(`${allsharelist[count].Numberoflikes}`) + 1, isliked: true, };
                            }
                            return i;
                        });
                        setAllsharelist(newStateforlike);
                    }
                    setShowNotificationMessage("Like has been submitted successfully")
                    setShowNotification(true);
                    closenotification()
                } else {
                    const newStateforlike = allsharelist.map((i = typeof any, index) => {
                        if (index === count) {
                            return { ...i, Numberoflikes: parseInt(`${allsharelist[count].Numberoflikes}`) - 1, isliked: false };
                        }
                        return i;
                    });
                    setAllsharelist(newStateforlike);
                    setShowNotificationMessage("Like has been removed successfully")
                    setShowNotification(true);
                    closenotification()
                }

            }
        })));

    }

    function closenotification() {
        setTimeout(function () {
            setShowNotification(false);
            dispatch(notification_message_status(false))
        }, 5000);
    }

    function changevalueforurl(data) {
        let step3 = '';
        if (data !== null) {
            let step1 = data.replace("&", "~")
            let step2 = step1.replace("/", ".")
            step3 = step2.replace("/", ".")
        }
        return step3
    }
    function escapeSelector(selector) {
        return selector?.replace(/([ #;?%&,.+*~\':"!^$[\]()=>|/@])/g, '\\$1');
    }
    function dislikeapost(count, shareid) {

        var payload =
        {
            'email': useremail,
            'id': shareid,
            'token': usertoken
        }
        dispatch(jesssu_get_shared_dislike(payload, (res => {
            if (res.status === 200) {
                if (allsharelist[count].isdisliked === false) {
                    if (allsharelist[count].isliked === true) {
                        const newStatefordislike = allsharelist.map((i = typeof any, index) => {
                            if (index === count) {
                                return { ...i, Numberofdislikes: parseInt(`${allsharelist[count].Numberofdislikes}`) + 1, isdisliked: true, Numberoflikes: parseInt(`${allsharelist[count].Numberoflikes}`) - 1, isliked: false };
                            }
                            return i;
                        });
                        setAllsharelist(newStatefordislike);
                    } else {
                        const newStatefordislike = allsharelist.map((i = typeof any, index) => {
                            if (index === count) {
                                return { ...i, Numberofdislikes: parseInt(`${allsharelist[count].Numberofdislikes}`) + 1, isdisliked: true };
                            }
                            return i;
                        });
                        setAllsharelist(newStatefordislike);
                    }
                    setShowNotificationMessage("Dislike has been submitted successfully")
                    setShowNotification(true);
                    closenotification()

                } else {
                    const newStatefordislike = allsharelist.map((i = typeof any, index) => {
                        if (index === count) {
                            return { ...i, Numberofdislikes: parseInt(`${allsharelist[count].Numberofdislikes}`) - 1, isdisliked: false };
                        }
                        return i;
                    });
                    setAllsharelist(newStatefordislike);
                    setShowNotificationMessage("Dislike has been removed successfully")
                    setShowNotification(true);
                    closenotification()

                }
            }
        })));

    }

    function commentbox(shareid) {

        if (comment !== "") {
            var payload =
            {
                'email': useremail,
                'token': usertoken,
                'shareid': shareid,
                'comment': comment,
            }
            dispatch(jesssu_get_comments_for_sharefeed(payload, (res => {

                if (res.status === 200) {
                    const newState = allsharelist.map((j, index) => {
                        if (j._id === shareid) {
                            let commentdata = {
                                "user": useremail,
                                "comment": comment
                            }
                            j.comments.push(commentdata)
                            let data = { ...j, Numberofcomment: parseInt(`${allsharelist[index].Numberofcomment}`) + 1, };
                            getpostcomment(data)
                            return data;
                        }
                        return j;
                    });
                    setAllsharelist(newState);
                    setcomment("")
                    setShowNotificationMessage("Your comment has been posted successfully")
                    setShowNotification(true);
                    closenotification()
                }
            })));
        }
    }
    const getpostcomment = (data) => {
        // setpostmessage("")
        setsinglepost(data)
        var payload =
        {
            'email': useremail,
            "shareid": data._id,
            'token': usertoken
        }
        dispatch(jesssu_get_comments_for_single_post(payload, (res => {
            if (res.status === 200) {
                res.data.map((i) => {
                    setpostcomment(current => [...current, i])
                })
            }
        })));

    }
    const closeNotification = () => {

        setShowNotification(false);
        dispatch(notification_message_status(false))
    };
    const handleChangeComment = (e) => {
        setcomment(e.target.value)
    }
    const clearpost = () => {
        setpostcomment([])
        setsinglepost(typeof any)
    }

    const get_profile_details = () => {
        let payload = {
            "token": state_account.profile.token,
            "email": state_account.profile.email,
        }

        dispatch(get_user_profile_details(payload, (res) => {
            if (res.status === 200) {
                // console.log(res.data)
                dispatch(user_signin(res.data));
            }
        }))
    }
    async function addProductinAudit(id) {
        let payload = {
            'token': state_account.profile.token,
            'email': state_account.profile.email,
            "COO": state_account.profile.COO,
        };
        dispatch(get_product_details(payload, id, (res) => {
            get_recently_visited_products();
            return res.data
        }))
    }

    async function redirectToProductPagefromSocial(id) {
        let payload = {
            'token': state_account.profile.token,
            'email': state_account.profile.email,
            "COO": state_account.profile.COO,
        };
        dispatch(get_product_details(payload, id, (res) => {
            if (res.data && res.data.length > 0) {
                if (wishlist.includes(res?.data[0]?.SKU) === false) {
                    addproductinwishlist(res.data[0])
                }
                window.open(res.data[0]?.channels[0].url, '_blank');
                get_recently_visited_products();
            }
        }))
    }

    let personalbrandIndexlength = 1
    if (state_account.profile.branddata === undefined) {
        personalbrandIndexlength = 1
    } else {
        personalbrandIndexlength = state_account.profile.branddata.length
    }

    const CategoryRightArrow = categoryIndex + 5 >= allcategories.length - 1
    const ShareRightArrow = shareIndex + 4 >= allsharelist.length - 1
    const brandRightArrow = brandIndex + 5 >= allbrands.length - 1
    const newArrivalRightArrow = newArrivalIndex + 4 >= suggestedlist.length - 1
    const personalbrandRightArrow = personalbrandIndex + 6 >= personalbrandIndexlength - 1
    const newArrivaltodayRightArrow = newArrivalIndex + 4 >= newarivallist.length - 1
    const recentlyVisitedRightArrow = recentlyVisitedIndex + 4 >= recentlyVisitedProductlist.length - 1
    const recommendedViewProductArrow = recommendedViewProductIndex + 4 >= recommendedViewProductList.length - 1
    const recommendedShareProductArrow = recommendedShareProductIndex + 4 >= recommendedShareProductList.length - 1
    const recommendedLikeProductArrow = recommendedLikeProductIndex + 4 >= recommendedLikeProductList.length - 1
    const recommendedCommentProductArrow = recommendedCommentProductIndex + 4 >= recommendedCommentProductList.length - 1

    return (
        <>
            {view === true && <>
                <div className="thetop"></div>
                <Header />
                <Helmet>
                    <title>Shop Trending Products & Discover Deals | Jesssu</title>
                    <meta name="description" content="Browse a curated selection of trending products and exclusive deals. Find what you're looking for with ease and enjoy a seamless shopping experience. Discover your next favorite item today!" />
                    <meta name="keywords" content="Shop, Trending products, deals" />
                </Helmet>
                <section id="hero" className="shop_slider">
                    <div id="carousel-example-generic" className="carousel slide" data-ride="carousel" style={{ width: "100%", overflow: "hidden" }}>
                        {/* Banner Container with Aspect Ratio */}
                        <div className="carousel-inner home_first_slider banner-data" role="listbox" style={{ paddingTop: "40%", position: "relative" }}>
                            {bannerList.filter(i => i.bannername === "Top-Banner").length === 0 && (
                                <>
                                    <div className="item active" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                        <Link to={"/products/any&newarrivals=true"}>
                                            <LazyLoadImage
                                                src="images/home/slider1.png"
                                                alt="New arrival banner image"
                                                className="img-responsive slider_img"
                                                style={{ width: "100%", height: "auto", objectFit: "cover" }}
                                            />
                                        </Link>
                                    </div>
                                    <div className="item" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                        <Link to="/products/any&newarrivals=true">
                                            <LazyLoadImage
                                                src="images/home/slider2.png"
                                                alt="New arrival banner image"
                                                className="img-responsive slider_img"
                                                style={{ width: "100%", height: "auto", objectFit: "cover" }}
                                            />
                                        </Link>
                                    </div>
                                    
                                </>
                            )}

                            {bannerList.filter(i => i.bannername === "Top-Banner").map((i, index) => (
                                <div className={index === 1 ? "item active" : "item"} key={index} style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                    <Link to={`/products/any&category=${changevalueforurl(i.bannertype)}`}>
                                        <LazyLoadImage
                                            src={i.bannerimage}
                                            alt={`${i.bannertype} category banner image`}
                                            className="img-responsive slider_img"
                                            style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                        />
                                    </Link>
                                </div>
                            ))}
                        </div>
                        {/* Carousel Controls */}
                        <a className="right carousel-control" href="#carousel-example-generic" role="button" data-slide="next">
                            <span className="glyphicon glyphicon-chevron-left fa-solid fa-arrow-right-long" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </a>
                        <a className="left carousel-control" href="#carousel-example-generic" role="button" data-slide="prev">
                            <span className="glyphicon glyphicon-chevron-right fa-solid fa-arrow-left-long" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </a>
                    </div>
                    <div className="free_shipping">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4">
                                    <Link to={`/shop`}>
                                        <div className="display_flex">
                                            <div className="free_ship_icon">
                                                <LazyLoadImage src="images/home/shop-home.png" alt="Shop icon" className="img-responsive" />
                                            </div>
                                            <div className="free_content">
                                                <p className="text-bold-banner-footer">Shop</p>
                                                <p className="text-light-banner-footer">Shop the trending collection of fashion products</p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-md-4">
                                    <Link to={`/social`}>
                                        <div className="display_flex">
                                            <div className="free_ship_icon">
                                                <LazyLoadImage src="images/home/share-home.png" alt="Share icon" className="img-responsive" />
                                            </div>
                                            <div className="free_content">
                                                <p className="text-bold-banner-footer">Share</p>
                                                <p className="text-light-banner-footer">Connect with your friends <br /> and share your liking</p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-md-4">
                                    <Link to={`/stash`}>
                                        <div className="display_flex">
                                            <div className="free_ship_icon">
                                                <LazyLoadImage src="images/home/stash-home.png" alt="Stash icon" className="img-responsive" />
                                            </div>
                                            <div className="free_content">
                                                <p className="text-bold-banner-footer">Stash</p>
                                                <p className="text-light-banner-footer">Add your favorite items to <br /> stash and create your looks</p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- Slider Section Closed Here--> */}

                <div className="modal fade" id="exampleModal" tabIndex="10" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="feed_details modal-dialog offer_dialog modal-md">
                        <div className="modal-content feed_modal_content">
                            <button type="button" className="close modal_close" data-dismiss="modal">&times;</button>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="select_for-box home_page_dialog">
                                            <div className="select_for_img"> <LazyLoadImage src="images/home/new-arrival-1.png" alt="New arrival banner image" className="img-responsive" />
                                                <div className="badge_pro">50%</div>
                                            </div>
                                            <div className="select_for_text">
                                                <p>T-Shirt Summber Vibes</p>
                                                <ul className="list-unstyled display_flex space_between2">
                                                    <li><a className="real_price">$89.99</a></li>
                                                    <li><span className="old_price">$111.99</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="write_message">
                                            <form>
                                                <textarea rows="6" cols="10" className="form-control" placeholder="message here"></textarea>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="dropdown"> <a data-toggle="dropdown" className="share_with_btn" >
                                            Share With <i className="fa-solid fa-angle-down"></i>
                                        </a>
                                            <ul className="dropdown-menu multi-level" role="menu" aria-labelledby="dropdownMenu">
                                                <li><a >Public</a></li>
                                                <li><a >All friends</a></li>
                                                <li className="dropdown dropdown-submenu"> <a className="dropdown-toggle" data-toggle="dropdown">Specific friends</a>
                                                    <ul className="dropdown-menu specific_friend_container">
                                                        {[1, 2, 3, 4, 5, 6].map((i) => (
                                                            <ul className="display_flex space_between2 specific_friend" key={`sharepopup${i}`}>
                                                                <li><LazyLoadImage src="images/home/cate_for2.jpg" alt="Friend icon" /></li>
                                                                <li>Carmen Ellis</li>
                                                                <li className="friend_send_btn">Send</li>
                                                            </ul>))}
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <p><a className="modal_share_btn">Share</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <!-- New Arrival Section Start--> */}
                <div className="new_arrival">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                {bannerList.filter(i => i.bannername === "Middle-Banner-I").length === 0 ? (<Link to={"/products/any&newarrivals=true"}>
                                    <div className="new_arrival_item"> <LazyLoadImage src="images/shop/arrival-fashion.png" alt="New arrival banner image" className="img-responsive" />
                                    </div>
                                </Link>) :
                                    (
                                        <Link to={`/products/any&category=${changevalueforurl(bannerList.find(i => i.bannername === "Middle-Banner-I").bannertype)}`}>
                                            <div className="new_arrival_item">
                                                <LazyLoadImage src={bannerList.find(i => i.bannername === "Middle-Banner-I").bannerimage} alt={`${bannerList.find(i => i.bannername === "Middle-Banner-I").bannertype} category banner image`} className="img-responsive" />
                                            </div>
                                        </Link>
                                    )}
                            </div>
                            <div className="col-md-3 col-xs-6">
                                {bannerList.filter(i => i.bannername === "Middle-Banner-II").length === 0 ? (<Link to={"/products/any&search=men%20t-shirts"}>
                                    <div className="new_arrival_item"> <LazyLoadImage src="images/shop/new-look.png" alt="Men tshirt banner image" className="img-responsive" />

                                    </div>
                                </Link>) : (<Link to={`/products/any&category=${changevalueforurl(bannerList.find(i => i.bannername === "Middle-Banner-II").bannertype)}`}>
                                    <div className="new_arrival_item"> <LazyLoadImage src={bannerList.find(i => i.bannername === "Middle-Banner-II").bannerimage} alt={`${bannerList.find(i => i.bannername === "Middle-Banner-II").bannertype} category banner image`} className="img-responsive" />

                                    </div>
                                </Link>)}
                            </div>
                            <div className="col-md-3 col-xs-6">
                                {bannerList.filter(i => i.bannername === "Middle-Banner-II").length < 1 ? (<Link to={"/products/any&search=Women%20jackets"}>
                                    <div className="new_arrival_item"> <LazyLoadImage src="images/shop/new-look1.png" alt="Women jacket banner image" className="img-responsive" />
                                    </div>
                                </Link>) : (
                                    <Link to={`/products/any&category=${changevalueforurl(bannerList.filter(i => i.bannername === "Middle-Banner-II")[1]?.bannertype || '')}`}>
                                        <div className="new_arrival_item">
                                            <LazyLoadImage src={bannerList.filter(i => i.bannername === "Middle-Banner-II")[1]?.bannerimage || ''} alt={`${bannerList.filter(i => i.bannername === "Middle-Banner-II")[1]?.bannertype} category banner image`} className="img-responsive" />
                                        </div>
                                    </Link>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- New Arrival Section Closed-->
    
           <!-- Category Section Start --> */}
                {/* <Category /> */}
                {allcategories.length > 0 &&
                    <div className="category_for">
                        <div className="container">
                            <h2 className="title-text text-center">Categories for you</h2>
                            <div className="curusol_category_section">
                                <button onClick={() => handlePrevClick("Category")} disabled={categoryIndex === 0} className="arrow_btn">
                                    {categoryIndex === "0" ?
                                        <LazyLoadImage src="images/shop/arrow-left.svg" alt="Left arrow inactive icon" />
                                        : <LazyLoadImage src="images/shop/arrow-left-active.svg" alt="Left arrow active icon" />}
                                </button>
                                <div onWheel={(e) => handleScroll(e, "Category")} className="scroll-section-wrap" >
                                    {allcategories.slice(categoryIndex, categoryIndex + 6).map((cat, i) =>
                                        <div className="col-md-3 item" key={`category${i}`}>
                                            <a href={`/subcategories/${changevalueforurl(cat._id)}`}>
                                                <div className="category_for_circle">
                                                    <div className="category_for_img"><Link to={`/products/any&category=${changevalueforurl(cat._id)}`}><LazyLoadImage src={`${cat.image && /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(cat.image)
                                                        ? cat.image
                                                        : "/images/defultimg.svg"
                                                        }`} alt={`${cat?._id} category image`} /></Link></div>
                                                    <p><a href={`/subcategories/${changevalueforurl(cat._id)}`} data-tip data-for={`categoryName${i}`}>{cat._id !== null && cat._id !== undefined ? `${cat._id.substring(0, 8)}...` : ""}</a></p>
                                                    <ReactTooltip id={`categoryName${i}`} place="top" effect="solid">
                                                        {cat._id}
                                                    </ReactTooltip>
                                                </div>
                                            </a>
                                        </div>
                                    )}
                                </div>
                                <button onClick={() => handleNextClick("Category")} disabled={categoryIndex + 6 >= allcategories.length} className="arrow_btn">
                                    {CategoryRightArrow === true ? <LazyLoadImage src="images/shop/arrow-right.svg" alt="Right arrow inactive icon" />
                                        : <LazyLoadImage src="images/shop/arrow-right-active.svg" alt="Right arrow active icon" />}
                                </button>
                            </div>

                        </div>
                    </div>}

                {/* <!--Category Section Closed -->
    
           <!-- All Share Section Start--> */}

                {recentlyVisitedProductlist.length > 4 &&
                    <div className="product_today">
                        <div className="container">
                            <h3 className="text-center">Recently Visited Products</h3>

                            <div className="curusol_share_section curusol_category_section">
                                <button onClick={() => handlePrevClick("RecentlyVisited")} disabled={recentlyVisitedIndex === 0} className="arrow_btn">
                                    {recentlyVisitedIndex === 0 ?
                                        <LazyLoadImage src="images/shop/arrow-left.svg" alt="Left arrow inactive icon" />
                                        : <LazyLoadImage src="images/shop/arrow-left-active.svg" alt="Left arrow active icon" />}
                                </button>
                                <div className="scroll-section-wrap" onWheel={(e) => handleScroll(e, "RecentlyVisited")} style={{ overflow: "auto" }}>
                                    {recentlyVisitedProductlist.slice(recentlyVisitedIndex, recentlyVisitedIndex + 4).map((product, i) =>
                                        <div className="col-md-5 home_page_dialog item" key={`newarrival${i}`}>
                                            <a className="product-box">
                                                <a className="select_for_img img-responsive" href={`${product?.channels[0]?.url}`} target='_blank' onClick={() => { wishlist.includes(product.SKU) ? "" : addproductinwishlist(product), addProductinAudit(product?.SKU) }}>
                                                    {/* <a > */}
                                                    <LazyLoadImage src={`${product.MainImage && /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(product.MainImage)
                                                        ? product.MainImage
                                                        : "/images/defultimg.svg"
                                                        }`} alt={`${product.Name} image`} />
                                                    {/* </a> */}
                                                    {product.RetailPrice > product.SalePrice &&
                                                        <div className="badge_pro">{parseFloat(((product.RetailPrice - product.SalePrice) / product.RetailPrice) * 100).toFixed(0)}% off</div>}
                                                    <div className="like_share_product">
                                                        <div className="social_section_show"></div>
                                                        <div className="social_section">
                                                            {wishlist.includes(product.SKU) === true &&
                                                                <a data-toggle="modal" data-target="#defult" onClick={() => addproductinwishlist(product)} className="social_section_left"><i className="fa-regular fa-heart" style={{ "background": "#ffa71d" }}></i></a>}
                                                            {wishlist.includes(product.SKU) === false &&
                                                                <a data-toggle="modal" data-target="#defult" onClick={() => addproductinwishlist(product)} className="social_section_left"><i className="fa-regular fa-heart" ></i></a>}
                                                            <a data-toggle="modal" data-target={`#example-sharemodel${escapeSelector(product.SKU)}`} onClick={() => { getproductdataforshare(product) }} className="social_section_right"><i className="fa-solid fa-share"></i></a>
                                                        </div>
                                                    </div>
                                                </a>
                                                {product?.channelIcon && (
                                                    <a href={`${product?.channels[0]?.url}`} target="_blank" rel="noopener noreferrer">
                                                        <img
                                                            src={product.channelIcon}
                                                            alt={`${product?.channels[0]?.channel_name} logo`}
                                                            className="channel-logo"
                                                            style={{ width: "40px", height: "auto", marginBottom: "8px" }} // Adjust styles as needed
                                                        />
                                                    </a>
                                                )}
                                                <div className="select_for_text">
                                                    <a href={`${product?.channels[0]?.url}`} target='_blank' onClick={() => { wishlist.includes(product.SKU) ? "" : addproductinwishlist(product), addProductinAudit(product?.SKU) }} data-tip data-for={`registerTip${i}`}>{(product.Name) !== null ? (product.Name).substring(0, 32) : (product.Name)}</a>
                                                    <ReactTooltip id={`registerTip${i}`} place="top" effect="solid">
                                                        {product.Name}
                                                    </ReactTooltip>
                                                    <ul className="list-unstyled display_flex space_between2">
                                                        <li><a href={`${product?.channels[0]?.url}`} target='_blank' onClick={() => { wishlist.includes(product.SKU) ? "" : addproductinwishlist(product), addProductinAudit(product?.SKU) }} className="real_price">{product.COO === "USA" ? "$" : "₹"}{product.SalePrice}</a></li>
                                                        {product.SalePrice !== product.RetailPrice && <li><span className="old_price">{product.COO === "USA" ? "$" : "₹"}{product.RetailPrice}</span></li>}                                                    </ul>
                                                </div>
                                            </a>
                                        </div>
                                    )}
                                </div>
                                <button onClick={() => handleNextClick("RecentlyVisited")} disabled={recentlyVisitedIndex + 4 >= recentlyVisitedProductlist.length} className="arrow_btn">
                                    {recentlyVisitedRightArrow === true ?
                                        <LazyLoadImage src="images/shop/arrow-right.svg" alt="Right arrow inactive icon" />
                                        : <LazyLoadImage src="images/shop/arrow-right-active.svg" alt="Right arrow active icon" />}
                                </button>
                            </div>
                        </div>
                    </div>}

                {/* Recommended product list based on user product view */}
                {recommendedViewProductList.length > 4 &&
                    <div className="product_today">
                        <div className="container">
                            <h3 className="text-center">Recommended for you</h3>

                            <div className="curusol_share_section curusol_category_section">
                                <button onClick={() => handlePrevClick("RecommendedViewProduct")} disabled={recommendedViewProductIndex === 0} className="arrow_btn">
                                    {recommendedViewProductIndex === 0 ?
                                        <LazyLoadImage src="images/shop/arrow-left.svg" alt="Left arrow inactive icon" />
                                        : <LazyLoadImage src="images/shop/arrow-left-active.svg" alt="Left arrow active icon" />}
                                </button>
                                <div className="scroll-section-wrap" onWheel={(e) => handleScroll(e, "RecommendedViewProduct")} style={{ overflow: "auto" }}>
                                    {recommendedViewProductList.slice(recommendedViewProductIndex, recommendedViewProductIndex + 4).map((product, i) =>
                                        <div className="col-md-5 home_page_dialog item" key={`newarrival${i}`}>
                                            <a className="product-box">
                                                <a className="select_for_img img-responsive" href={`${product?.channels[0]?.url}`} target='_blank' onClick={() => { wishlist.includes(product.SKU) ? "" : addproductinwishlist(product), addProductinAudit(product?.SKU) }}>
                                                    <LazyLoadImage src={`${product.MainImage && /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(product.MainImage)
                                                        ? product.MainImage
                                                        : "/images/defultimg.svg"
                                                        }`} alt={`${product.Name} image`} />
                                                    {product.RetailPrice > product.SalePrice &&
                                                        <div className="badge_pro">{parseFloat(((product.RetailPrice - product.SalePrice) / product.RetailPrice) * 100).toFixed(0)}% off</div>}
                                                    <div className="like_share_product">
                                                        <div className="social_section_show"></div>
                                                        <div className="social_section">
                                                            {wishlist.includes(product.SKU) === true &&
                                                                <a data-toggle="modal" data-target="#defult" onClick={() => addproductinwishlist(product)} className="social_section_left"><i className="fa-regular fa-heart" style={{ "background": "#ffa71d" }}></i></a>}
                                                            {wishlist.includes(product.SKU) === false &&
                                                                <a data-toggle="modal" data-target="#defult" onClick={() => addproductinwishlist(product)} className="social_section_left"><i className="fa-regular fa-heart" ></i></a>}
                                                            <a data-toggle="modal" data-target={`#example-sharemodel${escapeSelector(product.SKU)}`} onClick={() => { getproductdataforshare(product) }} className="social_section_right"><i className="fa-solid fa-share"></i></a>
                                                        </div>
                                                    </div>
                                                </a>
                                                {product?.channelIcon && (
                                                    <a href={`${product?.channels[0]?.url}`} target="_blank" rel="noopener noreferrer">
                                                        <img
                                                            src={product.channelIcon}
                                                            alt={`${product?.channels[0]?.channel_name} logo`}
                                                            className="channel-logo"
                                                            style={{ width: "40px", height: "auto", marginBottom: "8px" }} // Adjust styles as needed
                                                        />
                                                    </a>
                                                )}
                                                <div className="select_for_text">
                                                    <a href={`${product?.channels[0]?.url}`} target='_blank' onClick={() => { wishlist.includes(product.SKU) ? "" : addproductinwishlist(product), addProductinAudit(product?.SKU) }} data-tip data-for={`registerTip${i}`}>{(product.Name) !== null ? (product.Name).substring(0, 32) : (product.Name)}</a>
                                                    <ReactTooltip id={`registerTip${i}`} place="top" effect="solid">
                                                        {product.Name}
                                                    </ReactTooltip>
                                                    <ul className="list-unstyled display_flex space_between2">
                                                        <li><a href={`${product?.channels[0]?.url}`} target='_blank' onClick={() => { wishlist.includes(product.SKU) ? "" : addproductinwishlist(product), addProductinAudit(product?.SKU) }} className="real_price">{product.COO === "USA" ? "$" : "₹"}{product.SalePrice}</a></li>
                                                        {product.SalePrice !== product.RetailPrice && <li><span className="old_price">{product.COO === "USA" ? "$" : "₹"}{product.RetailPrice}</span></li>}                                                    </ul>
                                                </div>
                                            </a>
                                        </div>
                                    )}
                                </div>
                                <button onClick={() => handleNextClick("RecommendedViewProduct")} disabled={recommendedViewProductIndex + 4 >= recommendedViewProductList.length} className="arrow_btn">
                                    {recommendedViewProductArrow === true ?
                                        <LazyLoadImage src="images/shop/arrow-right.svg" alt="Right arrow inactive icon" />
                                        : <LazyLoadImage src="images/shop/arrow-right-active.svg" alt="Right arrow active icon" />}
                                </button>
                            </div>
                        </div>
                    </div>}



                {allsharelist.length > 0 &&
                    <div className="all_share">
                        <div className="container">
                            <h2 className="title-text text-center">Shared cloths by others</h2>
                            <h3 className="title-text text-center"> please check it out bellow. You may like it</h3>

                            <div className="curusol_share_section curusol_category_section">
                                <button onClick={() => handlePrevClick("Share")} disabled={shareIndex === 0} className="arrow_btn">
                                    {shareIndex === "0" ?
                                        <LazyLoadImage src="images/shop/arrow-left.svg" alt="Left arrow inactive icon" />
                                        : <LazyLoadImage src="images/shop/arrow-left-active.svg" alt="Left arrow active icon" />}
                                </button>
                                <div onWheel={(e) => handleScroll(e, "Share")} className="scroll-section-wrap">
                                    {allsharelist.slice(shareIndex, shareIndex + 4).map((share, i) =>
                                        <div className="col-md-7 item" key={`share${i}`}>
                                            <div className="all_share_box">
                                                <div className="all_share_box_head">
                                                    <div className="display_flex space_between2">
                                                        <div className="display_flex">
                                                            <LazyLoadImage src={`${share.imageofsharedby[0] && (share?.providerofsharedby[0] === "Phonelogin" ? /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(share.imageofsharedby[0]) : {})
                                                                ? share.imageofsharedby[0]
                                                                : share.genderofsharedby === undefined ? "images/onboarding/man.png" : share.genderofsharedby[0] === "Men" ? "images/onboarding/man.png" : "images/onboarding/woman.png"
                                                                }`} alt="User profile image" className="user_img" />

                                                            < div className="img_text">
                                                                <p className="text-bold">{share.sharedby[0]}</p>
                                                                <p>{new Intl.DateTimeFormat('en-GB', {
                                                                    month: 'short',
                                                                    day: '2-digit',
                                                                    year: 'numeric',
                                                                }).format(new Date(share.createdAt))}</p>
                                                            </div>
                                                        </div>
                                                        <AllSocialExpireData key={`allShareexpire${share._id}`} item={share} onExpirationChange={handleExpirationChangeallSocial} />
                                                    </div>
                                                </div>
                                                <div className="all_share_box_body">
                                                    {share.type === 1 &&
                                                        <Link to={`#`}><LazyLoadImage onClick={() => redirectToProductPagefromSocial(share.productid)} src={
                                                            `${share.postimage && /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(share.postimage)
                                                                ? share.postimage
                                                                : "/images/defultimg.svg"
                                                            }`
                                                        } alt="User-shared image" className="img-responsive" />
                                                        </Link>
                                                    }
                                                    {share.type === 3 &&
                                                        <a><LazyLoadImage src={`${share.postimage && /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(share.postimage)
                                                            ? share.postimage
                                                            : "/images/defultimg.svg"
                                                            }`} alt="User-shared image" className="img-responsive" /></a>
                                                    }
                                                    {share.type === 6 &&

                                                        <div className="all_share_box_body_img">
                                                            {share.layout[0] === "2-2-1" ?
                                                                <div className="page2_right_area_section page2_right_area" style={{ backgroundImage: share.backgroundimage === "" || share.backgroundimage === undefined ? "linear-gradient(179deg, #6398AF, #FEAB79)" : `url(${share.backgroundimage})` }}>
                                                                    <div className="page2_right_area_wrapp">
                                                                        {/* <div className="col-md-6 col-xs-6"> */}
                                                                        <div className="classic_img_area_section classic_img_area"> {share.bundleproduct[0] !== null &&
                                                                            <Link to={`#`}><LazyLoadImage onClick={() => redirectToProductPagefromSocial(share.bundleproduct[0].productid)} src={
                                                                                `${share.bundleproduct[0].productimage && /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(share.bundleproduct[0].productimage)
                                                                                    ? share.bundleproduct[0].productimage
                                                                                    : "/images/defultimg.svg"
                                                                                }`} alt="User-shared image" className="classic_img" /></Link>}
                                                                        </div>
                                                                        {/* </div> */}
                                                                        {/* <div className="col-md-6 col-xs-6"> */}
                                                                        <div className="classic_img_area_section classic_img_area"> {share.bundleproduct[1] !== null &&
                                                                            <Link to={`#`}><LazyLoadImage onClick={() => redirectToProductPagefromSocial(share.bundleproduct[1].productid)} src={`${share.bundleproduct[1].productimage && /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(share.bundleproduct[1].productimage)
                                                                                ? share.bundleproduct[1].productimage
                                                                                : "/images/defultimg.svg"
                                                                                }`} alt="User-shared image" className="classic_img" /></Link>}
                                                                        </div>
                                                                        {/* </div> */}
                                                                        {/* </div>
                                                            <div className="row"> */}
                                                                        {/* <div className="col-md-12 col-xs-12"> */}
                                                                        <div className="classic_img_area_section classic_img_area"> {share.bundleproduct[2] !== null &&
                                                                            <Link to={`#`}><LazyLoadImage onClick={() => redirectToProductPagefromSocial(share.bundleproduct[2].productid)} src={`${share.bundleproduct[2].productimage && /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(share.bundleproduct[2].productimage)
                                                                                ? share.bundleproduct[2].productimage
                                                                                : "/images/defultimg.svg"
                                                                                }`} alt="User-shared image" className="classic_img" /></Link>}
                                                                        </div>
                                                                        {/* </div> */}
                                                                        {/* </div>
                                                            <div className="row"> */}
                                                                        {/* <div className="col-md-6 col-xs-6"> */}
                                                                        <div className="classic_img_area_section classic_img_area"> {share.bundleproduct[3] !== null &&
                                                                            <Link to={`#`}><LazyLoadImage onClick={() => redirectToProductPagefromSocial(share.bundleproduct[3].productid)} src={`${share.bundleproduct[3].productimage && /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(share.bundleproduct[3].productimage)
                                                                                ? share.bundleproduct[3].productimage
                                                                                : "/images/defultimg.svg"
                                                                                }`} alt="User-shared image" className="classic_img" /></Link>}
                                                                        </div>
                                                                        {/* </div> */}
                                                                        {/* <div className="col-md-6 col-xs-6"> */}
                                                                        <div className="classic_img_area_section classic_img_area"> {share.bundleproduct[4] !== null &&
                                                                            <Link to={`#`}><LazyLoadImage onClick={() => redirectToProductPagefromSocial(share.bundleproduct[4].productid)} src={`${share.bundleproduct[4].productimage && /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(share.bundleproduct[4].productimage)
                                                                                ? share.bundleproduct[4].productimage
                                                                                : "/images/defultimg.svg"
                                                                                }`} alt="User-shared image" className="classic_img" /></Link>}
                                                                        </div>
                                                                        {/* </div> */}
                                                                    </div>
                                                                </div>
                                                                :
                                                                share.layout[0] === "3-2" ?
                                                                    <div className="page2_right_area_section page2_right_area" style={{ backgroundImage: share.backgroundimage === "" || share.backgroundimage === undefined ? "linear-gradient(179deg, #6398AF, #FEAB79)" : `url(${share.backgroundimage})` }}>
                                                                        <div className="page2_right_area_wrapp">
                                                                            {/* <div className="col-md-6 col-xs-6"> */}
                                                                            <div className="classic_img_area_section classic_img_area"> {share.bundleproduct[0] !== null &&
                                                                                <Link to={`#`}><LazyLoadImage onClick={() => redirectToProductPagefromSocial(share.bundleproduct[0].productid)} src={`${share.bundleproduct[0].productimage && /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(share.bundleproduct[0].productimage)
                                                                                    ? share.bundleproduct[0].productimage
                                                                                    : "/images/defultimg.svg"
                                                                                    }`} alt="User-shared image" className="classic_img" /></Link>}
                                                                            </div>
                                                                            {/* </div> */}
                                                                            {/* <div className="col-md-6 col-xs-6"> */}
                                                                            <div className="classic_img_area_section classic_img_area"> {share.bundleproduct[1] !== null &&
                                                                                <Link to={`#`}><LazyLoadImage onClick={() => redirectToProductPagefromSocial(share.bundleproduct[1].productid)} src={`${share.bundleproduct[1].productimage && /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(share.bundleproduct[1].productimage)
                                                                                    ? share.bundleproduct[1].productimage
                                                                                    : "/images/defultimg.svg"
                                                                                    }`} alt="User-shared image" className="classic_img" /></Link>}
                                                                            </div>
                                                                            {/* </div> */}
                                                                            {/* </div>
                                                                <div className="row"> */}
                                                                            {/* <div className="col-md-6 col-xs-6"> */}
                                                                            <div className="classic_img_area_section classic_img_area"> {share.bundleproduct[2] !== null &&
                                                                                <Link to={`#`}><LazyLoadImage onClick={() => redirectToProductPagefromSocial(share.bundleproduct[2].productid)} src={`${share.bundleproduct[2].productimage && /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(share.bundleproduct[2].productimage)
                                                                                    ? share.bundleproduct[2].productimage
                                                                                    : "/images/defultimg.svg"
                                                                                    }`} alt="User-shared image" className="classic_img" /></Link>}
                                                                            </div>
                                                                            {/* </div> */}
                                                                            {/* <div className="col-md-6 col-xs-6"> */}
                                                                            <div className="classic_img_area_section classic_img_area"> {share.bundleproduct[3] !== null &&
                                                                                <Link to={`#`}><LazyLoadImage onClick={() => redirectToProductPagefromSocial(share.bundleproduct[3].productid)} src={`${share.bundleproduct[3].productimage && /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(share.bundleproduct[3].productimage)
                                                                                    ? share.bundleproduct[3].productimage
                                                                                    : "/images/defultimg.svg"
                                                                                    }`} alt="User-shared image" className="classic_img" /></Link>}
                                                                            </div>
                                                                            {/* </div> */}
                                                                            {/* </div>
                                                                <div className="row"> */}
                                                                            {/* <div className="col-md-12 col-xs-12"> */}
                                                                            <div className="classic_img_area_section classic_img_area"> {share.bundleproduct[4] !== null &&
                                                                                <Link to={`#`}><LazyLoadImage onClick={() => redirectToProductPagefromSocial(share.bundleproduct[4].productid)} src={`${share.bundleproduct[4].productimage && /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(share.bundleproduct[4].productimage)
                                                                                    ? share.bundleproduct[4].productimage
                                                                                    : "/images/defultimg.svg"
                                                                                    }`} alt="User-shared image" className="classic_img" /></Link>}
                                                                            </div>
                                                                            {/* </div> */}
                                                                        </div>
                                                                    </div>
                                                                    : share.layout[0] === "4-4" || share.layout[0] === "" ?
                                                                        <div className="page2_right_area_section page2_right_area" style={{ backgroundImage: share.backgroundimage === "" || share.backgroundimage === undefined ? "linear-gradient(179deg, #6398AF, #FEAB79)" : `url(${share.backgroundimage})` }}>
                                                                            <div className="page2_right_area_wrapp">
                                                                                {/* <div className="col-md-6 col-xs-6"> */}
                                                                                <div className="classic_img_area_section classic_img_area">
                                                                                    {share.bundleproduct[0] !== null &&
                                                                                        <Link to={`#`}><LazyLoadImage onClick={() => redirectToProductPagefromSocial(share.bundleproduct[0].productid)} src={`${share.bundleproduct[0].productimage && /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(share.bundleproduct[0].productimage)
                                                                                            ? share.bundleproduct[0].productimage
                                                                                            : "/images/defultimg.svg"
                                                                                            }`} alt="User-shared image" className="classic_img" /></Link>}
                                                                                </div>
                                                                                {/* </div> */}
                                                                                {/* <div className="col-md-6 col-xs-6"> */}
                                                                                <div className="classic_img_area_section classic_img_area"> {share.bundleproduct[1] !== null &&
                                                                                    <Link to={`#`}><LazyLoadImage onClick={() => redirectToProductPagefromSocial(share.bundleproduct[1].productid)} src={`${share.bundleproduct[1].productimage && /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(share.bundleproduct[1].productimage)
                                                                                        ? share.bundleproduct[1].productimage
                                                                                        : "/images/defultimg.svg"
                                                                                        }`} alt="User-shared image" className="classic_img" /></Link>}
                                                                                </div>
                                                                                {/* </div> */}
                                                                                {/* </div>
                                                                    <div className="row"> */}
                                                                                {/* <div className="col-md-6 col-xs-6"> */}
                                                                                <div className="classic_img_area_section classic_img_area"> {share.bundleproduct[2] !== null &&
                                                                                    <Link to={`#`}><LazyLoadImage onClick={() => redirectToProductPagefromSocial(share.bundleproduct[2].productid)} src={`${share.bundleproduct[2].productimage && /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(share.bundleproduct[2].productimage)
                                                                                        ? share.bundleproduct[2].productimage
                                                                                        : "/images/defultimg.svg"
                                                                                        }`} alt="User-shared image" className="classic_img" /></Link>}
                                                                                </div>
                                                                                {/* </div> */}
                                                                                {/* <div className="col-md-6 col-xs-6"> */}
                                                                                <div className="classic_img_area_section classic_img_area"> {share.bundleproduct[3] !== null &&
                                                                                    <Link to={`#`}><LazyLoadImage onClick={() => redirectToProductPagefromSocial(share.bundleproduct[3].productid)} src={`${share.bundleproduct[3].productimage && /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(share.bundleproduct[3].productimage)
                                                                                        ? share.bundleproduct[3].productimage
                                                                                        : "/images/defultimg.svg"
                                                                                        }`} alt="User-shared image" className="classic_img" /></Link>}
                                                                                </div>
                                                                                {/* </div> */}
                                                                            </div>
                                                                        </div> : <></>}
                                                        </div>
                                                    }

                                                </div>
                                                <div className="all_share_box_footer">
                                                    <div className={share?.expiresStatus ? "social_disable forpointer" : "forpointer"}>
                                                        <ul className="list-unstyled display_flex space_between">
                                                            {share.isliked === true ?
                                                                <li>
                                                                    <a className="active_color" data-tip data-for={`liketip${i}`}>
                                                                        <i className="fa-solid fa-thumbs-up" onClick={() => { likeapost(i, share._id) }}>
                                                                            <div className="action_section">
                                                                                <span>{share.Numberoflikes}</span>
                                                                            </div>
                                                                        </i>
                                                                    </a>
                                                                </li>
                                                                :
                                                                <li>
                                                                    <a data-tip data-for={`liketip${i}`}>
                                                                        <i className="fa-solid fa-thumbs-up" onClick={() => { likeapost(i, share._id) }}>
                                                                            <div className="action_section">
                                                                                <span>{share.Numberoflikes}</span>
                                                                            </div>
                                                                        </i>
                                                                    </a>
                                                                </li>}
                                                            <ReactTooltip id={`liketip${i}`} place="top" effect="solid">
                                                                {(share.likeby[0] || []).map((likedata, i) => ((i <= 5 &&
                                                                    <div key={`likedata${i}`}>{likedata}</div>)))}
                                                            </ReactTooltip>
                                                            {share.isdisliked === true ?
                                                                <li>
                                                                    <a className="active_color" data-tip data-for={`disliketip${i}`}>
                                                                        <i className="fa-solid fa-thumbs-down" onClick={() => { dislikeapost(i, share._id) }} >
                                                                            <div className="action_section">
                                                                                <span>{share.Numberofdislikes}</span>
                                                                            </div>
                                                                        </i>
                                                                    </a>
                                                                </li>
                                                                :
                                                                <li>
                                                                    <a data-tip data-for={`disliketip${i}`}>
                                                                        <i className="fa-solid fa-thumbs-down" onClick={() => { dislikeapost(i, share._id) }}>
                                                                            <div className="action_section">
                                                                                <span>{share.Numberofdislikes}</span>
                                                                            </div>
                                                                        </i>
                                                                    </a>
                                                                </li>}
                                                            <ReactTooltip id={`disliketip${i}`} place="top" effect="solid">
                                                                {(share.dislikeby[0] || []).map((dislikedata, i) => ((i <= 5 && <div key={`dislike${i}`}>{dislikedata}</div>)))}
                                                            </ReactTooltip>

                                                            {share.isshared === true ?
                                                                <li>
                                                                    <a className="active_color" data-tip data-for={`sharetip${i}`}>
                                                                        <i className="fa-solid fa-share" data-toggle="modal" data-target={`#create_post_alertreshare${share._id}`} onClick={() => { getresharedata(share) }}>
                                                                            <div className="action_section">
                                                                                <span>{share.Numberofshares}</span>
                                                                            </div>
                                                                        </i>
                                                                    </a>
                                                                </li>
                                                                :
                                                                <li>
                                                                    <a data-tip data-for={`sharetip${i}`}>
                                                                        <i className="fa-solid fa-share" data-toggle="modal" data-target={`#create_post_alertreshare${share._id}`} onClick={() => { getresharedata(share) }}>
                                                                            <div className="action_section">
                                                                                <span>{share.Numberofshares}</span>
                                                                            </div>
                                                                        </i>
                                                                    </a>
                                                                </li>}
                                                            <ReactTooltip id={`sharetip${i}`} place="top" effect="solid">
                                                                {(share.sharedby || []).map((sharedata, i) => ((i <= 5 && <div key={`sharedata${i}`}>{sharedata}</div>)))}
                                                            </ReactTooltip>
                                                            <li>
                                                                <a>
                                                                    <i className="fa-solid fa-comment" data-toggle="modal" data-target="#exampleModal2" onClick={() => { getpostcomment(share) }}>
                                                                        <div className="action_section">
                                                                            <span>{share.Numberofcomment}</span>
                                                                        </div>
                                                                    </i>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <button onClick={() => handleNextClick("Share")} disabled={shareIndex + 4 >= allsharelist.length} className="arrow_btn">
                                    {ShareRightArrow === true ? <LazyLoadImage src="images/shop/arrow-right.svg" alt="Right arrow inactive icon" />
                                        : <LazyLoadImage src="images/shop/arrow-right-active.svg" alt="Right arrow active icon" />}
                                </button>
                            </div>
                            {/* </div> */}
                            {/* </div> */}
                        </div>
                    </div>}
                {/* <!-- All Share Section Closed -->
    
           <!-- All Brand Section  Start --> */}
                <div className="all_brand">
                    <div className="container">
                        <h2 className="title-text text-center">All Brands</h2>

                        <div className="curusol_share_section curusol_category_section">
                            <button onClick={() => handlePrevClick("Brand")} disabled={brandIndex === 0} className="arrow_btn">
                                {brandIndex === "0" ?
                                    <LazyLoadImage src="images/shop/arrow-left.svg" alt="Left arrow inactive icon" />
                                    : <LazyLoadImage src="images/shop/arrow-left-active.svg" alt="Left arrow active icon" />}
                            </button>
                            <div onWheel={(e) => handleScroll(e, "Brand")} className="scroll-section-wrap">

                                {allbrands.slice(brandIndex, brandIndex + 6).map((brand, i) =>

                                    <div className="col-md-3 center_text item">
                                        <div>
                                            <div className="all_brand_item center_text" key={`brand${i}`}>
                                                <Link to={`/products/any&brand=${changevalueforurl(brand._id)}`} className="all_brand_img"> <LazyLoadImage src={`${brand.image && /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(brand.image)
                                                    ? brand.image
                                                    : "/images/defultimg.svg"
                                                    }`} alt={`${brand?._id} brand image`} className="brand_img_height img-responsive" /></Link>
                                            </div>
                                            <Link to={`/products/any&brand=${changevalueforurl(brand._id)}`} style={{ "color": "#000" }} data-tip data-for={`brandName${i}`}>
                                                {brand._id !== null && brand._id !== undefined ? brand._id.substring(0, 7) : ""}

                                            </Link>
                                            <ReactTooltip id={`brandName${i}`} place="top" effect="solid">
                                                {brand._id}
                                            </ReactTooltip>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <button onClick={() => handleNextClick("Brand")} disabled={brandIndex + 5 >= allbrands.length} className="arrow_btn">
                                {brandRightArrow === true ? <LazyLoadImage src="images/shop/arrow-right.svg" alt="Right arrow inactive icon" />
                                    : <LazyLoadImage src="images/shop/arrow-right-active.svg" alt="Right arrow active icon" />}
                            </button>
                        </div>
                    </div>
                </div>
                {/* <!-- All Brand Section Closed -->
    
            <!-- Category For Section Start --> */}
                <div className="category_for">
                    <div className="container">
                        <h2 className="text-center">Shop by price</h2>
                        <div className="shop-price-grid">
                            <div className="category_for_circle">
                                <div className="category_for_img shop_by_price bg_2">
                                    <p><Link to={`/products/any&minprice=0&maxprice=99`}>Under <span>{state_account.profile.COO === "USA" ? "$" : "₹"}99</span></Link></p>
                                </div>
                            </div>
                            <div className="category_for_circle">
                                <div className="category_for_img shop_by_price bg_3">
                                    <p><Link to={`/products/any&minprice=100&maxprice=199`}>Under <span>{state_account.profile.COO === "USA" ? "$" : "₹"}199</span></Link></p>
                                </div>
                            </div>
                            <div className="category_for_circle">
                                <div className="category_for_img shop_by_price bg_4">
                                    <p><Link to={`/products/any&minprice=200&maxprice=299`}>Under <span>{state_account.profile.COO === "USA" ? "$" : "₹"}299</span></Link></p>
                                </div>
                            </div>
                            <div className="category_for_circle">
                                <div className="category_for_img shop_by_price bg_5">
                                    <p><Link to={`/products/any&minprice=300&maxprice=399`}>Under <span>{state_account.profile.COO === "USA" ? "$" : "₹"}399</span></Link></p>
                                </div>
                            </div>
                            <div className="category_for_circle">
                                <div className="category_for_img shop_by_price bg_6">
                                    <p><Link to={`/products/any&minprice=400&maxprice=499`}>Under <span>{state_account.profile.COO === "USA" ? "$" : "₹"}499</span></Link></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Category For Section Closed -->
            <!-- Select For Section Start --> */}

                {/* Recommended product list based on user product like */}

                {recommendedLikeProductList.length > 4 &&
                    <div className="product_today">
                        <div className="container">
                            <h3 className="text-center">Recommended for you</h3>

                            <div className="curusol_share_section curusol_category_section">
                                <button onClick={() => handlePrevClick("RecommendedLikeProduct")} disabled={recommendedLikeProductIndex === 0} className="arrow_btn">
                                    {recommendedLikeProductIndex === 0 ?
                                        <LazyLoadImage src="images/shop/arrow-left.svg" alt="Left arrow inactive icon" />
                                        : <LazyLoadImage src="images/shop/arrow-left-active.svg" alt="Left arrow active icon" />}
                                </button>
                                <div className="scroll-section-wrap" onWheel={(e) => handleScroll(e, "RecommendedLikeProduct")} style={{ overflow: "auto" }}>
                                    {recommendedLikeProductList.slice(recommendedLikeProductIndex, recommendedLikeProductIndex + 4).map((product, i) =>
                                        <div className="col-md-5 home_page_dialog item" key={`newarrival${i}`}>
                                            <a className="product-box">
                                                <a className="select_for_img img-responsive" href={`${product?.channels[0]?.url}`} target='_blank' onClick={() => { wishlist.includes(product.SKU) ? "" : addproductinwishlist(product), addProductinAudit(product?.SKU) }}>
                                                    <LazyLoadImage src={`${product.MainImage && /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(product.MainImage)
                                                        ? product.MainImage
                                                        : "/images/defultimg.svg"
                                                        }`} alt={`${product.Name} image`} />
                                                    {product.RetailPrice > product.SalePrice &&
                                                        <div className="badge_pro">{parseFloat(((product.RetailPrice - product.SalePrice) / product.RetailPrice) * 100).toFixed(0)}% off</div>}
                                                    <div className="like_share_product">
                                                        <div className="social_section_show"></div>
                                                        <div className="social_section">
                                                            {wishlist.includes(product.SKU) === true &&
                                                                <a data-toggle="modal" data-target="#defult" onClick={() => addproductinwishlist(product)} className="social_section_left"><i className="fa-regular fa-heart" style={{ "background": "#ffa71d" }}></i></a>}
                                                            {wishlist.includes(product.SKU) === false &&
                                                                <a data-toggle="modal" data-target="#defult" onClick={() => addproductinwishlist(product)} className="social_section_left"><i className="fa-regular fa-heart" ></i></a>}
                                                            <a data-toggle="modal" data-target={`#example-sharemodel${escapeSelector(product.SKU)}`} onClick={() => { getproductdataforshare(product) }} className="social_section_right"><i className="fa-solid fa-share"></i></a>
                                                        </div>
                                                    </div>
                                                </a>
                                                {product?.channelIcon && (
                                                    <a href={`${product?.channels[0]?.url}`} target="_blank" rel="noopener noreferrer">
                                                        <img
                                                            src={product.channelIcon}
                                                            alt={`${product?.channels[0]?.channel_name} logo`}
                                                            className="channel-logo"
                                                            style={{ width: "40px", height: "auto", marginBottom: "8px" }} // Adjust styles as needed
                                                        />
                                                    </a>
                                                )}
                                                <div className="select_for_text">
                                                    <a href={`${product?.channels[0]?.url}`} target='_blank' onClick={() => { wishlist.includes(product.SKU) ? "" : addproductinwishlist(product), addProductinAudit(product?.SKU) }} data-tip data-for={`registerTip${i}`}>{(product.Name) !== null ? (product.Name).substring(0, 32) : (product.Name)}</a>
                                                    <ReactTooltip id={`registerTip${i}`} place="top" effect="solid">
                                                        {product.Name}
                                                    </ReactTooltip>
                                                    <ul className="list-unstyled display_flex space_between2">
                                                        <li><a href={`${product?.channels[0]?.url}`} target='_blank' onClick={() => { wishlist.includes(product.SKU) ? "" : addproductinwishlist(product), addProductinAudit(product?.SKU) }} className="real_price">{product.COO === "USA" ? "$" : "₹"}{product.SalePrice}</a></li>
                                                        {product.SalePrice !== product.RetailPrice && <li><span className="old_price">{product.COO === "USA" ? "$" : "₹"}{product.RetailPrice}</span></li>}                                                    </ul>
                                                </div>
                                            </a>
                                        </div>
                                    )}
                                </div>
                                <button onClick={() => handleNextClick("RecommendedLikeProduct")} disabled={recommendedLikeProductIndex + 4 >= recommendedLikeProductList.length} className="arrow_btn">
                                    {recommendedLikeProductArrow === true ?
                                        <LazyLoadImage src="images/shop/arrow-right.svg" alt="Right arrow inactive icon" />
                                        : <LazyLoadImage src="images/shop/arrow-right-active.svg" alt="Right arrow active icon" />}
                                </button>
                            </div>
                        </div>
                    </div>}





                {suggestedlist?.length > 0 &&
                    <div className="select_for">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-10">
                                    <h3>Selected Just for you</h3>
                                </div>
                                <div className="col-md-2" onClick={() => gotosuggestedproductlist()}>
                                    <p><a className="see_more">See More</a></p>
                                </div>
                            </div>
                            {/* {suggestedview===false && */}
                            <div className="curusol_share_section curusol_category_section">
                                <button onClick={() => handlePrevClick("SelectedProduct")} disabled={selectedProductIndex === 0} className="arrow_btn">
                                    {selectedProductIndex === "0" ?
                                        <LazyLoadImage src="images/shop/arrow-left.svg" alt="Left arrow inactive icon" />
                                        : <LazyLoadImage src="images/shop/arrow-left-active.svg" alt="Left arrow active icon" />}
                                </button>
                                <div className="scroll-section-wrap" onWheel={(e) => handleScroll(e, "SelectedProduct")}>
                                    {suggestedlist.slice(selectedProductIndex, selectedProductIndex + 4).map((product, i) =>
                                        <div className="col-md-6 home_page_dialog item" key={`newarrivals${i}`}>
                                            <a className="product-box" >
                                                <a className="select_for_img img-responsive" href={`${product?.channels[0]?.url}`} target='_blank' onClick={() => { wishlist.includes(product.SKU) ? "" : addproductinwishlist(product), addProductinAudit(product?.SKU) }}>
                                                    {/* <a href={`/product/${product.SKU}`}> */}
                                                    <LazyLoadImage src={`${product.MainImage && /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(product.MainImage)
                                                        ? product.MainImage
                                                        : "/images/defultimg.svg"
                                                        }`} alt={`${product.Name} image`} />
                                                    {/* </a> */}
                                                    {product.RetailPrice > product.SalePrice &&
                                                        <div className="badge_pro">{parseFloat(((product.RetailPrice - product.SalePrice) / product.RetailPrice) * 100).toFixed(0)}% off</div>}
                                                    <div className="like_share_product">
                                                        <div className="social_section_show"></div>
                                                        <div className="social_section">
                                                            {wishlist.includes(product.SKU) === true &&
                                                                <a data-toggle="modal" data-target="#defult" onClick={() => addproductinwishlist(product)}><i className="fa-regular fa-heart" style={{ "background": "#ffa71d" }}></i></a>}
                                                            {wishlist.includes(product.SKU) === false &&
                                                                <a data-toggle="modal" data-target="#defult" onClick={() => addproductinwishlist(product)}><i className="fa-regular fa-heart" ></i></a>}
                                                            <a data-toggle="modal" data-target={`#example-sharemodel${escapeSelector(product.SKU)}`} onClick={() => { getproductdataforshare(product) }}><i className="fa-solid fa-share"></i></a>
                                                        </div>
                                                    </div>
                                                </a>
                                                {product?.channelIcon && (
                                                    <a href={`${product?.channels[0]?.url}`} target="_blank" rel="noopener noreferrer">
                                                        <img
                                                            src={product.channelIcon}
                                                            alt={`${product?.channels[0]?.channel_name} logo`}
                                                            className="channel-logo"
                                                            style={{ width: "40px", height: "auto", marginBottom: "8px" }} // Adjust styles as needed
                                                        />
                                                    </a>
                                                )}
                                                <div className="select_for_text">
                                                    <a href={`${product?.channels[0]?.url}`} target='_blank' onClick={() => { wishlist.includes(product.SKU) ? "" : addproductinwishlist(product), addProductinAudit(product?.SKU) }} data-tip data-for={`suggestedTip${i}`}>{(product.Name) !== null ? (product.Name).substring(0, 32) : (product.Name)}</a>
                                                    <ReactTooltip id={`suggestedTip${i}`} place="top" effect="solid">
                                                        {product.Name}
                                                    </ReactTooltip>
                                                    <ul className="list-unstyled display_flex space_between2">
                                                        <li><a href={`${product?.channels[0]?.url}`} target='_blank' onClick={() => { wishlist.includes(product.SKU) ? "" : addproductinwishlist(product), addProductinAudit(product?.SKU) }} className="real_price">{product.COO === "USA" ? "$" : "₹"}{product.SalePrice}</a></li>
                                                        {product.SalePrice !== product.RetailPrice && <li><span className="old_price">{product.COO === "USA" ? "$" : "₹"}{product.RetailPrice}</span></li>}
                                                    </ul>
                                                </div>
                                            </a>
                                        </div>
                                    )}
                                </div>
                                <button onClick={() => handleNextClick("SelectedProduct")} disabled={selectedProductIndex + 4 >= suggestedlist.length} className="arrow_btn">
                                    {newArrivalRightArrow === true ?
                                        <LazyLoadImage src="images/shop/arrow-right.svg" alt="Right arrow inactive icon" />
                                        : <LazyLoadImage src="images/shop/arrow-right-active.svg" alt="Right arrow active icon" />}
                                </button>
                            </div>
                        </div>
                    </div>}

                {/* <!-- Select for section closed -->
    
            <!--All Brand Section Start --> */}
                {state_account &&
                    state_account.profile &&
                    state_account.profile.branddata &&
                    state_account.profile.branddata.length > 0 &&
                    <div className="all_brand_section select_brand">
                        <div className="container">
                            <h2 className="text-center">Your Brands</h2>
                            <div className="curusol_share_section curusol_category_section">
                                <button onClick={() => handlePrevClick("PersonalBrand")} disabled={personalbrandIndex === 0} className="arrow_btn">
                                    {personalbrandIndex === "0" ?
                                        <LazyLoadImage src="images/shop/arrow-left.svg" alt="Left arrow inactive icon" />
                                        : <LazyLoadImage src="images/shop/arrow-left-active.svg" alt="Left arrow active icon" />}
                                </button>
                                <div onWheel={(e) => handleScroll(e, "PersonalBrand")} className="scroll-section-wrap">
                                    {state_account.profile.branddata.slice(personalbrandIndex, personalbrandIndex + 6).map((branddata, i) =>
                                        <div className="col-md-5 item center_text item" key={`personalbrand${i}`} style={{ width: "160px" }}>
                                            <div>
                                                <div className="all_brand_box brand_new_box" style={{ width: "100%" }}>
                                                    {/* <Link to={`/products/any&brand=${changevalueforurl(branddata.brandname)}`}> */}
                                                    <LazyLoadImage src={`${branddata.image && /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(branddata.image)
                                                        ? branddata.image
                                                        : "/images/defultimg.svg"
                                                        }`} alt={`${branddata.brandname} brand image`} className="img-responsive" />
                                                    {/* </Link> */}
                                                </div>
                                                <Link to={`/products/any&brand=${changevalueforurl(branddata.brandname)}`} data-tip data-for={`brand${i}`} style={{ "color": "#000" }}>
                                                    {branddata.brandname !== null && branddata.brandname !== undefined ? branddata.brandname.substring(0, 7) : ""}
                                                </Link>
                                                <ReactTooltip id={`brand${i}`} place="top" effect="solid">
                                                    {branddata.brandname}
                                                </ReactTooltip>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <button onClick={() => handleNextClick("PersonalBrand")} disabled={personalbrandIndex + 6 >= allcategories.length} className="arrow_btn">
                                    {personalbrandRightArrow === true ?
                                        <LazyLoadImage src="images/shop/arrow-right.svg" alt="Right arrow inactive icon" />
                                        : <LazyLoadImage src="images/shop/arrow-right-active.svg" alt="Right arrow active icon" />}
                                </button>
                            </div>

                        </div>
                    </div>}

                {/* <!-- All Brand Section Closed --> */}


                {/* Recommended product list based on user share product */}

                {recommendedShareProductList.length > 4 &&
                    <div className="product_today">
                        <div className="container">
                            <h3 className="text-center">Recommended for you</h3>

                            <div className="curusol_share_section curusol_category_section">
                                <button onClick={() => handlePrevClick("RecommendedShareProduct")} disabled={recommendedShareProductIndex === 0} className="arrow_btn">
                                    {recommendedShareProductIndex === 0 ?
                                        <LazyLoadImage src="images/shop/arrow-left.svg" alt="Left arrow inactive icon" />
                                        : <LazyLoadImage src="images/shop/arrow-left-active.svg" alt="Left arrow active icon" />}
                                </button>
                                <div className="scroll-section-wrap" onWheel={(e) => handleScroll(e, "RecommendedShareProduct")} style={{ overflow: "auto" }}>
                                    {recommendedShareProductList.slice(recommendedShareProductIndex, recommendedShareProductIndex + 4).map((product, i) =>
                                        <div className="col-md-5 home_page_dialog item" key={`newarrival${i}`}>
                                            <a className="product-box">
                                                <a className="select_for_img img-responsive" href={`${product?.channels[0]?.url}`} target='_blank' onClick={() => { wishlist.includes(product.SKU) ? "" : addproductinwishlist(product), addProductinAudit(product?.SKU) }}>
                                                    <LazyLoadImage src={`${product.MainImage && /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(product.MainImage)
                                                        ? product.MainImage
                                                        : "/images/defultimg.svg"
                                                        }`} alt={`${product.Name} image`} />
                                                    {product.RetailPrice > product.SalePrice &&
                                                        <div className="badge_pro">{parseFloat(((product.RetailPrice - product.SalePrice) / product.RetailPrice) * 100).toFixed(0)}% off</div>}
                                                    <div className="like_share_product">
                                                        <div className="social_section_show"></div>
                                                        <div className="social_section">
                                                            {wishlist.includes(product.SKU) === true &&
                                                                <a data-toggle="modal" data-target="#defult" onClick={() => addproductinwishlist(product)} className="social_section_left"><i className="fa-regular fa-heart" style={{ "background": "#ffa71d" }}></i></a>}
                                                            {wishlist.includes(product.SKU) === false &&
                                                                <a data-toggle="modal" data-target="#defult" onClick={() => addproductinwishlist(product)} className="social_section_left"><i className="fa-regular fa-heart" ></i></a>}
                                                            <a data-toggle="modal" data-target={`#example-sharemodel${escapeSelector(product.SKU)}`} onClick={() => { getproductdataforshare(product) }} className="social_section_right"><i className="fa-solid fa-share"></i></a>
                                                        </div>
                                                    </div>
                                                </a>
                                                {product?.channelIcon && (
                                                    <a href={`${product?.channels[0]?.url}`} target="_blank" rel="noopener noreferrer">
                                                        <img
                                                            src={product.channelIcon}
                                                            alt={`${product?.channels[0]?.channel_name} logo`}
                                                            className="channel-logo"
                                                            style={{ width: "40px", height: "auto", marginBottom: "8px" }} // Adjust styles as needed
                                                        />
                                                    </a>
                                                )}
                                                <div className="select_for_text">
                                                    <a href={`${product?.channels[0]?.url}`} target='_blank' onClick={() => { wishlist.includes(product.SKU) ? "" : addproductinwishlist(product), addProductinAudit(product?.SKU) }} data-tip data-for={`registerTip${i}`}>{(product.Name) !== null ? (product.Name).substring(0, 32) : (product.Name)}</a>
                                                    <ReactTooltip id={`registerTip${i}`} place="top" effect="solid">
                                                        {product.Name}
                                                    </ReactTooltip>
                                                    <ul className="list-unstyled display_flex space_between2">
                                                        <li><a href={`${product?.channels[0]?.url}`} target='_blank' onClick={() => { wishlist.includes(product.SKU) ? "" : addproductinwishlist(product), addProductinAudit(product?.SKU) }} className="real_price">{product.COO === "USA" ? "$" : "₹"}{product.SalePrice}</a></li>
                                                        {product.SalePrice !== product.RetailPrice && <li><span className="old_price">{product.COO === "USA" ? "$" : "₹"}{product.RetailPrice}</span></li>}                                                    </ul>
                                                </div>
                                            </a>
                                        </div>
                                    )}
                                </div>
                                <button onClick={() => handleNextClick("RecommendedShareProduct")} disabled={recommendedShareProductIndex + 4 >= recommendedShareProductList.length} className="arrow_btn">
                                    {recommendedShareProductArrow === true ?
                                        <LazyLoadImage src="images/shop/arrow-right.svg" alt="Right arrow inactive icon" />
                                        : <LazyLoadImage src="images/shop/arrow-right-active.svg" alt="Right arrow active icon" />}
                                </button>
                            </div>
                        </div>
                    </div>}


                {/* <!-- Product Today Start--> */}
                {newarivallist.length > 4 &&
                    <div className="product_today">
                        <div className="container">
                            <h3 className="text-center">Product in today</h3>

                            <div className="curusol_share_section curusol_category_section">
                                <button onClick={() => handlePrevClick("NewArrivals")} disabled={newArrivalIndex === 0} className="arrow_btn">
                                    {newArrivalIndex === "0" ?
                                        <LazyLoadImage src="images/shop/arrow-left.svg" alt="Left arrow inactive icon" />
                                        : <LazyLoadImage src="images/shop/arrow-left-active.svg" alt="Left arrow active icon" />}
                                </button>
                                <div className="scroll-section-wrap" onWheel={(e) => handleScroll(e, "NewArrivals")} >
                                    {newarivallist.slice(newArrivalIndex, newArrivalIndex + 4).map((product, i) =>
                                        <div className="col-md-5 home_page_dialog item" key={`newarrival${i}`}>
                                            <a className="product-box">
                                                <a className="select_for_img img-responsive" href={`${product?.channels[0]?.url}`} target='_blank' onClick={() => { wishlist.includes(product.SKU) ? "" : addproductinwishlist(product), addProductinAudit(product?.SKU) }}>
                                                    {/* <a > */}
                                                    <LazyLoadImage src={`${product.MainImage && /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(product.MainImage)
                                                        ? product.MainImage
                                                        : "/images/defultimg.svg"
                                                        }`} alt={`${product.Name} image`} />
                                                    {/* </a> */}
                                                    {product.RetailPrice > product.SalePrice &&
                                                        <div className="badge_pro">{parseFloat(((product.RetailPrice - product.SalePrice) / product.RetailPrice) * 100).toFixed(0)}% off</div>}
                                                    <div className="like_share_product">
                                                        <div className="social_section_show"></div>
                                                        <div className="social_section">
                                                            {wishlist.includes(product.SKU) === true &&
                                                                <a data-toggle="modal" data-target="#defult" onClick={() => addproductinwishlist(product)} className="social_section_left"><i className="fa-regular fa-heart" style={{ "background": "#ffa71d" }}></i></a>}
                                                            {wishlist.includes(product.SKU) === false &&
                                                                <a data-toggle="modal" data-target="#defult" onClick={() => addproductinwishlist(product)} className="social_section_left"><i className="fa-regular fa-heart" ></i></a>}
                                                            <a data-toggle="modal" data-target={`#example-sharemodel${escapeSelector(product.SKU)}`} onClick={() => { getproductdataforshare(product) }} className="social_section_right"><i className="fa-solid fa-share"></i></a>
                                                        </div>
                                                    </div>
                                                </a>
                                                {product?.channelIcon && (
                                                    <a href={`${product?.channels[0]?.url}`} target="_blank" rel="noopener noreferrer">
                                                        <img
                                                            src={product.channelIcon}
                                                            alt={`${product?.channels[0]?.channel_name} logo`}
                                                            className="channel-logo"
                                                            style={{ width: "40px", height: "auto", marginBottom: "8px" }} // Adjust styles as needed
                                                        />
                                                    </a>
                                                )}
                                                <div className="select_for_text">
                                                    <a href={`${product?.channels[0]?.url}`} target='_blank' onClick={() => { wishlist.includes(product.SKU) ? "" : addproductinwishlist(product), addProductinAudit(product?.SKU) }} data-tip data-for={`registerTip${i}`}>{(product.Name) !== null ? (product.Name).substring(0, 32) : (product.Name)}</a>
                                                    <ReactTooltip id={`registerTip${i}`} place="top" effect="solid">
                                                        {product.Name}
                                                    </ReactTooltip>
                                                    <ul className="list-unstyled display_flex space_between2">
                                                        <li><a href={`${product?.channels[0]?.url}`} target='_blank' onClick={() => { wishlist.includes(product.SKU) ? "" : addproductinwishlist(product), addProductinAudit(product?.SKU) }} className="real_price">{product.COO === "USA" ? "$" : "₹"}{product.SalePrice}</a></li>
                                                        {product.SalePrice !== product.RetailPrice && <li><span className="old_price">{product.COO === "USA" ? "$" : "₹"}{product.RetailPrice}</span></li>}                                                    </ul>
                                                </div>
                                            </a>
                                        </div>
                                    )}
                                </div>
                                <button onClick={() => handleNextClick("NewArrivals")} disabled={newArrivalIndex + 4 >= newarivallist.length} className="arrow_btn">
                                    {newArrivaltodayRightArrow === true ?
                                        <LazyLoadImage src="images/shop/arrow-right.svg" alt="Right arrow inactive icon" />
                                        : <LazyLoadImage src="images/shop/arrow-right-active.svg" alt="Right arrow active icon" />}
                                </button>
                            </div>
                        </div>
                    </div>}

                {/* Recommended product list based on user share product */}

                {recommendedCommentProductList.length > 4 &&
                    <div className="product_today">
                        <div className="container">
                            <h3 className="text-center">Recommended for you</h3>

                            <div className="curusol_share_section curusol_category_section">
                                <button onClick={() => handlePrevClick("RecommendedCommentProduct")} disabled={recommendedCommentProductIndex === 0} className="arrow_btn">
                                    {recommendedCommentProductIndex === 0 ?
                                        <LazyLoadImage src="images/shop/arrow-left.svg" alt="Left arrow inactive icon" />
                                        : <LazyLoadImage src="images/shop/arrow-left-active.svg" alt="Left arrow active icon" />}
                                </button>
                                <div className="scroll-section-wrap" onWheel={(e) => handleScroll(e, "RecommendedCommentProduct")} style={{ overflow: "auto" }}>
                                    {recommendedCommentProductList.slice(recommendedCommentProductIndex, recommendedCommentProductIndex + 4).map((product, i) =>
                                        <div className="col-md-5 home_page_dialog item" key={`newarrival${i}`}>
                                            <a className="product-box">
                                                <a className="select_for_img img-responsive" href={`${product?.channels[0]?.url}`} target='_blank' onClick={() => { wishlist.includes(product.SKU) ? "" : addproductinwishlist(product), addProductinAudit(product?.SKU) }}>
                                                    <LazyLoadImage src={`${product.MainImage && /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(product.MainImage)
                                                        ? product.MainImage
                                                        : "/images/defultimg.svg"
                                                        }`} alt={`${product.Name} image`} />
                                                    {product.RetailPrice > product.SalePrice &&
                                                        <div className="badge_pro">{parseFloat(((product.RetailPrice - product.SalePrice) / product.RetailPrice) * 100).toFixed(0)}% off</div>}
                                                    <div className="like_share_product">
                                                        <div className="social_section_show"></div>
                                                        <div className="social_section">
                                                            {wishlist.includes(product.SKU) === true &&
                                                                <a data-toggle="modal" data-target="#defult" onClick={() => addproductinwishlist(product)} className="social_section_left"><i className="fa-regular fa-heart" style={{ "background": "#ffa71d" }}></i></a>}
                                                            {wishlist.includes(product.SKU) === false &&
                                                                <a data-toggle="modal" data-target="#defult" onClick={() => addproductinwishlist(product)} className="social_section_left"><i className="fa-regular fa-heart" ></i></a>}
                                                            <a data-toggle="modal" data-target={`#example-sharemodel${escapeSelector(product.SKU)}`} onClick={() => { getproductdataforshare(product) }} className="social_section_right"><i className="fa-solid fa-share"></i></a>
                                                        </div>
                                                    </div>
                                                </a>
                                                {product?.channelIcon && (
                                                    <a href={`${product?.channels[0]?.url}`} target="_blank" rel="noopener noreferrer">
                                                        <img
                                                            src={product.channelIcon}
                                                            alt={`${product?.channels[0]?.channel_name} logo`}
                                                            className="channel-logo"
                                                            style={{ width: "40px", height: "auto", marginBottom: "8px" }} // Adjust styles as needed
                                                        />
                                                    </a>
                                                )}
                                                <div className="select_for_text">
                                                    <a href={`${product?.channels[0]?.url}`} target='_blank' onClick={() => { wishlist.includes(product.SKU) ? "" : addproductinwishlist(product), addProductinAudit(product?.SKU) }} data-tip data-for={`registerTip${i}`}>{(product.Name) !== null ? (product.Name).substring(0, 32) : (product.Name)}</a>
                                                    <ReactTooltip id={`registerTip${i}`} place="top" effect="solid">
                                                        {product.Name}
                                                    </ReactTooltip>
                                                    <ul className="list-unstyled display_flex space_between2">
                                                        <li><a href={`${product?.channels[0]?.url}`} target='_blank' onClick={() => { wishlist.includes(product.SKU) ? "" : addproductinwishlist(product), addProductinAudit(product?.SKU) }} className="real_price">{product.COO === "USA" ? "$" : "₹"}{product.SalePrice}</a></li>
                                                        {product.SalePrice !== product.RetailPrice && <li><span className="old_price">{product.COO === "USA" ? "$" : "₹"}{product.RetailPrice}</span></li>}                                                    </ul>
                                                </div>
                                            </a>
                                        </div>
                                    )}
                                </div>
                                <button onClick={() => handleNextClick("RecommendedCommentProduct")} disabled={recommendedCommentProductIndex + 4 >= recommendedCommentProductList.length} className="arrow_btn">
                                    {recommendedCommentProductArrow === true ?
                                        <LazyLoadImage src="images/shop/arrow-right.svg" alt="Right arrow inactive icon" />
                                        : <LazyLoadImage src="images/shop/arrow-right-active.svg" alt="Right arrow active icon" />}
                                </button>
                            </div>
                        </div>
                    </div>}
                <div>
                    <AIAgent email={state_account.profile.email} token={state_account.profile.token} />
                </div>


            </>}
            {/* <!-- Product Today Section closed -->

            <!-- Footer Section Start --> */}
            {/* Comment Box */}
            <div className="modal fade" id="exampleModal2" tabIndex="10" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="feed_details modal-dialog offer_dialog modal-md">
                    <div className="modal-body">
                        <div className="friends-timeline-pop-up">
                            <div className="pop-up-section">'
                                <div className="pop-up-box">
                                    <button type="button" className="close modal_close" data-dismiss="modal" onClick={() => { clearpost() }}>&times;</button>
                                    <div className="row">
                                        <div className="col-md-6">
                                            {(singlepost.type === 1) &&
                                                <div className="post_nav_content" style={{ "width": "100%", "height": "100%", "paddingTop": "3rem" }}>
                                                    <LazyLoadImage src={`${singlepost.postimage && /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(singlepost.postimage)
                                                        ? singlepost.postimage
                                                        : "/images/defultimg.svg"
                                                        }`}
                                                        alt="User-shared image" className="img-responsive" />
                                                </div>
                                            }
                                            {(singlepost.type === 3) &&
                                                <div className="post_nav_content" style={{ "width": "100%", "height": "100%", "paddingTop": "3rem" }}>
                                                    <LazyLoadImage src={`${singlepost.postimage && /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(singlepost.postimage)
                                                        ? singlepost.postimage
                                                        : "/images/defultimg.svg"
                                                        }`}
                                                        alt="User-shared image" className="img-responsive" />
                                                </div>
                                            }
                                            {(singlepost.type === 6) &&
                                                <div className="post_nav_content" style={{ "height": "480px", "paddingTop": "3rem", "width": "100%" }}>
                                                    <div className="mylook_post">
                                                        <div data-toggle="buttons">
                                                            <label className="btn btn-default">
                                                                <input type="checkbox" name="var_id[]"
                                                                    autoComplete="off" value="" />
                                                                <div className="row">
                                                                    {singlepost.bundleproduct.length === 0 ? <></> :
                                                                        <div>
                                                                            {singlepost.layout === "2-2-1" ?
                                                                                <div className="classic_formate" style={{ backgroundImage: singlepost.backgroundimage === "" || singlepost.backgroundimage === undefined ? "linear-gradient(179deg, #6398AF, #FEAB79)" : `url(${singlepost.backgroundimage})` }}>
                                                                                    <div className="classic_formate_scroll1 no_scroll">
                                                                                        <div className="row">
                                                                                            <div className="col-md-5 col-xs-5">
                                                                                                <div className="classic_img_area">
                                                                                                    {singlepost.bundleproduct.length > 0 &&
                                                                                                        <LazyLoadImage src={
                                                                                                            `${singlepost.bundleproduct[0].productimage && /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(singlepost.bundleproduct[0].productimage)
                                                                                                                ? singlepost.bundleproduct[0].productimage
                                                                                                                : "/images/defultimg.svg"
                                                                                                            }`
                                                                                                        } alt="User-shared image" className="classic_img" />}
                                                                                                </div>
                                                                                                <div className="classic_img_area">
                                                                                                    {singlepost.bundleproduct.length > 1 &&
                                                                                                        <LazyLoadImage src={`${singlepost.bundleproduct[1].productimage && /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(singlepost.bundleproduct[1].productimage)
                                                                                                            ? singlepost.bundleproduct[1].productimage
                                                                                                            : "/images/defultimg.svg"
                                                                                                            }`} alt="User-shared image" className="classic_img" />}
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-md-3 col-xs-3">
                                                                                                <div className="classic-img-area2">
                                                                                                    {singlepost.bundleproduct.length > 2 &&
                                                                                                        <LazyLoadImage src={`${singlepost.bundleproduct[2].productimage && /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(singlepost.bundleproduct[2].productimage)
                                                                                                            ? singlepost.bundleproduct[2].productimage
                                                                                                            : "/images/defultimg.svg"
                                                                                                            }`} alt="" className="classic_img" />}
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-md-3 col-xs-3">
                                                                                                <div className="classic-img-area2">
                                                                                                    {singlepost.bundleproduct.length > 3 &&
                                                                                                        <LazyLoadImage src={`${singlepost.bundleproduct[3].productimage && /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(singlepost.bundleproduct[3].productimage)
                                                                                                            ? singlepost.bundleproduct[3].productimage
                                                                                                            : "/images/defultimg.svg"
                                                                                                            }`} alt="User-shared image" className="classic_img" />}
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-md-6 col-xs-6">
                                                                                                <div className="classic-img-area3">
                                                                                                    {singlepost.bundleproduct.length > 4 &&
                                                                                                        <LazyLoadImage src={`${singlepost.bundleproduct[4].productimage && /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(singlepost.bundleproduct[4].productimage)
                                                                                                            ? singlepost.bundleproduct[4].productimage
                                                                                                            : "/images/defultimg.svg"
                                                                                                            }`} alt="User-shared image" className="classic_img" />}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                singlepost.layout === "3-2" ?
                                                                                    <div className="classic_formate" style={{ backgroundImage: singlepost.backgroundimage === "" || singlepost.backgroundimage === undefined ? "linear-gradient(179deg, #6398AF, #FEAB79)" : `url(${singlepost.backgroundimage})` }}>
                                                                                        <div className="classic_formate_scroll1 no_scroll">
                                                                                            <div className="row">
                                                                                                <div className="col-md-4 col-xs-4">
                                                                                                    <div className="classic-img-area4">
                                                                                                        {singlepost.bundleproduct.length > 0 &&
                                                                                                            <LazyLoadImage src={`${singlepost.bundleproduct[0].productimage && /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(singlepost.bundleproduct[0].productimage)
                                                                                                                ? singlepost.bundleproduct[0].productimage
                                                                                                                : "/images/defultimg.svg"
                                                                                                                }`} alt="User-shared image" className="classic_img" />}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-4 col-xs-4">
                                                                                                    <div className="classic-img-area4">
                                                                                                        {singlepost.bundleproduct.length > 1 &&
                                                                                                            <LazyLoadImage src={`${singlepost.bundleproduct[1].productimage && /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(singlepost.bundleproduct[1].productimage)
                                                                                                                ? singlepost.bundleproduct[1].productimage
                                                                                                                : "/images/defultimg.svg"
                                                                                                                }`} alt="User-shared image" className="classic_img" />}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-4 col-xs-4">
                                                                                                    <div className="classic-img-area4">
                                                                                                        {singlepost.bundleproduct.length > 2 &&
                                                                                                            <LazyLoadImage src={`${singlepost.bundleproduct[2].productimage && /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(singlepost.bundleproduct[2].productimage)
                                                                                                                ? singlepost.bundleproduct[2].productimage
                                                                                                                : "/images/defultimg.svg"
                                                                                                                }`} alt="User-shared image" className="classic_img" />}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-6 col-xs-6">
                                                                                                    <div className="classic-img-area5">
                                                                                                        {singlepost.bundleproduct.length > 3 &&
                                                                                                            <LazyLoadImage src={`${singlepost.bundleproduct[3].productimage && /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(singlepost.bundleproduct[3].productimage)
                                                                                                                ? singlepost.bundleproduct[3].productimage
                                                                                                                : "/images/defultimg.svg"
                                                                                                                }`} alt="User-shared image" className="classic_img" />}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-6 col-xs-6">
                                                                                                    <div className="classic-img-area5">
                                                                                                        {singlepost.bundleproduct.length > 4 &&
                                                                                                            <LazyLoadImage src={`${singlepost.bundleproduct[4].productimage && /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(singlepost.bundleproduct[4].productimage)
                                                                                                                ? singlepost.bundleproduct[4].productimage
                                                                                                                : "/images/defultimg.svg"
                                                                                                                }`} alt="User-shared image" className="classic_img" />}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    : singlepost.layout === "4-4" || singlepost.layout === "" ?
                                                                                        <div className="classic_formate" style={{ backgroundImage: singlepost.backgroundimage === "" || singlepost.backgroundimage === undefined ? "linear-gradient(179deg, #6398AF, #FEAB79)" : `url(${singlepost.backgroundimage})` }} >
                                                                                            <div>
                                                                                                <div className="row">
                                                                                                    {singlepost.bundleproduct.length === 0
                                                                                                        ?
                                                                                                        <></>
                                                                                                        :
                                                                                                        singlepost.bundleproduct.map((data, key) => (
                                                                                                            <div className="col-md-6 col-xs-6" key={`bundle${key}`}>
                                                                                                                <div className="classic_img_area">
                                                                                                                    <LazyLoadImage src={`${singlepost.productimage && /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(singlepost.productimage)
                                                                                                                        ? singlepost.productimage
                                                                                                                        : "/images/defultimg.svg"
                                                                                                                        }`} alt="User-shared image" className="classic_img" />
                                                                                                                </div>
                                                                                                            </div>)
                                                                                                        )
                                                                                                    }

                                                                                                </div>
                                                                                            </div>

                                                                                        </div> : <></>
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className="col-md-6">
                                            <div className="comments-section">
                                                <div className="profile-comment-img-row">
                                                    <p>{singlepost.postname}</p>
                                                </div>
                                                <div className="timeline-comments">
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="friend-comments-section">
                                                                <p>{singlepost.Numberofcomment} comments</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {postcomment.length > 0 && <div className="timeline-brand-content">
                                                    {(postcomment || []).map((data, index) => (
                                                        <div className="comment-img-row" key={`post${index}`}>
                                                            <LazyLoadImage src={`${data.profileimage[0] && /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(data.profileimage[0])
                                                                ? data.profileimage[0]
                                                                : data.gender === undefined ? "/images/onboarding/man.png" : data.gender === "Men" ? "/images/onboarding/man.png" : "/images/onboarding/woman.png"
                                                                }`} alt="user profile image" />
                                                            {/* <LazyLoadImage src={data.profileimage[0] == "" && /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(data.profileimage[0]) ? "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y" : `${data.profileimage[0]}`} alt="" /> */}
                                                            <p>@{data.userfirstname[0]}</p>
                                                            <p>{data.comment}</p>
                                                        </div>))}

                                                </div>}
                                                <div className="timeline-comment-section">
                                                    <form method="post">
                                                        <div className="timeline-comment_text_area" >
                                                            <input className="timeline-social_page_comment_text" value={comment}
                                                                placeholder="write your comment..."
                                                                onChange={handleChangeComment} />
                                                            <a id="closecommentbox"><i
                                                                className="fa-solid fa-paper-plane" onClick={() => { commentbox(singlepost._id) }} style={{ "cursor": "pointer" }}></i></a>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            {
                reshare === true ? (
                    <Reshare shareddata={shareddata} pkey={"productdetails"} />
                ) : <></>
            }

            {
                state_account.showNotification ? (
                    <div className="addwishlist-pop-up-box">
                        <div className="add-wishlist-row-section">
                            <LazyLoadImage src="images/Icon metro-cancel.svg" alt="Cancel icon" onClick={closeNotification} />
                            <h5>{state_account.showNotificationMessage}</h5>
                        </div>
                    </div>
                ) : (
                    <></>)
            }
            {
                showNotification ? (
                    <div className="addwishlist-pop-up-box">
                        <div className="add-wishlist-row-section">
                            <LazyLoadImage src="images/Icon metro-cancel.svg" alt="Cancel icon" onClick={closeNotification} />
                            <h5>{showNotificationMessage}</h5>
                        </div>
                    </div>
                ) : (
                    <></>)
            }
            {
                productshare === true ? (
                    <Productshare productdata={shareddata} pkey={"productdetails"} />
                ) : <></>
            }
        </>
    );
}
export default Shop;