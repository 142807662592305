/* eslint-disable */
import React, { useEffect, useState } from "react";
// import ScriptTag from 'react-script-tag';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { get_categroylist_pagination, get_brandlist_pagination, get_categorylist_pagination_by_search, get_brandlist_pagination_by_search } from "../../store/category/api"
import { jesssu_user_profileimage_upload, jesssu_users_update_profile } from "../../store/account/api"
import { user_signin } from "../../store/account/accountActions"
import ReactTooltip from "react-tooltip";
import { Carousel } from 'react-responsive-carousel';
import { get_user_profile_details } from "../../store/account/api"

const Onboarding2 = () => {
	const dispatch = useDispatch();
	const state_account = useSelector(state => state.account);
	const [firstName, setfirstName] = useState('');
	const [lastName, setlastName] = useState('');
	const [age, setage] = useState(state_account.profile.age);
	const [gender, setgender] = useState(state_account.profile.gender);
	// const [COO, setCOO] = useState(state_account.profile.COO)
	const [profileImage, setprofileImage] = useState(state_account.profile.profileimage);
	const [categories, setCategories] = useState([]);
	const [brandlist, setBrandList] = useState([]);
	const [selectedCategorylist, setselectedCategorylist] = useState([]);
	// const [selectedBrandlist, setbrandlist] = useState([]);
	// const [isendlist, setIsEndList] = useState(false);
	const [carousel, setcarousel] = useState(false)
	const [selectedbrands, setSelectedBrands] = useState(state_account.profile.brand);
	const [selectedbrandname, setSelectedBrandName] = useState([]);
	const history = useNavigate();

	let offset = 0;
	const count = 160;
	// const listInnerRef = useRef();
	// let selectedbrandnamenew = []
	useEffect(() => {
		setcarousel(true);
		// var profile = state_account.profile;
		// if (profile.gender != undefined && profile.gender != null && profile.gender != "") {
		// 	setgender(profile.gender);
		// }
		// categories.forEach(element => {
		// 	selectedCategorylist.push(element._id)
		// });
		// if (profile.COO != undefined && profile.COO != null && profile.COO.length > 0) {
		// 	setCOO(profile.COO[0])
		// }
	});

	function selectCategory(category, index) {
		if (selectedCategorylist.some(e => e === category._id)) {
			const newList = selectedCategorylist.filter((item) => item !== category._id);
			setselectedCategorylist(newList);

		} else {
			setselectedCategorylist(current => [...current, category._id]);

		}

		if (categories[index].status === true) {
			const newcategorylist = categories.map((i = typeof any, count) => {
				if (count === index) {
					return { ...i, status: false };
				}
				return i;
			});
			setCategories(newcategorylist);
		} else {
			const newcategorylist = categories.map((i = typeof any, count) => {
				if (count === index) {
					return { ...i, status: true };
				}
				return i;
			});
			setCategories(newcategorylist);
		}

	}

	const upload_profile_image = (e) => {
		if (e) {
			const formData = new FormData();
			formData.append(
				"file",
				e.target.files[0]
			);
			jesssu_user_profileimage_upload(formData, (res) => {

				setprofileImage(res.data)
			})
		}
	}
	const update_user_store_profile = () => {
		var userpayload = {
			firstname: firstName,
			lastname: lastName,
			profileimage: profileImage,
			age: age,
			gender: gender,
			COO: state_account.profile.COO
		}
		dispatch(user_signin(userpayload));
		get_categories();
	}
	const update_user_store_category = () => {
		var categorypayload = {
			category: selectedCategorylist
		}
		dispatch(user_signin(categorypayload));
		get_brands()
	}
	const get_profile_details = () => {
		let payload = {
			"token": state_account.profile.token,
			"email": state_account.profile.email,
		}
		dispatch(get_user_profile_details(payload, (res) => { dispatch(user_signin(res.data));
		}))
	}
	const update_user_store_brand = () => {
		var brandpayload = {
			brand: selectedbrandname
		}
		dispatch(user_signin(brandpayload));
		const profilePayload = state_account.profile
		profilePayload.brand = selectedbrandname;
		profilePayload.category= selectedCategorylist;
		dispatch(jesssu_users_update_profile(profilePayload, (res) => {
			if (res.status === 200) {
				get_profile_details()
				history("/shop")
			}
		}));
	}
	// const handleChangeCat = (e) => {
	// 	let index = 0;
	// 	while (index < selectedCategorylist.length) {
	// 		if (e.target.value === selectedCategorylist[index]) {
	// 			selectedCategorylist.splice(index, 1);
	// 			break;
	// 		}
	// 		index++;
	// 	}
	// 	if (e.target.checked) {
	// 		selectedCategorylist.push(e.target.value)
	// 	}
	// }

	function selectBrand(brand, index) {
		if (selectedbrands.some(e => e === brand._id)) {
			const newList = selectedbrands.filter((item) => item !== brand._id);
			setSelectedBrands(newList);

		} else {
			setSelectedBrands(current => [...current, brand._id]);

		}

		if (brandlist[index].status === true) {
			const newbrandlist = brandlist.map((i = typeof any, count) => {
				if (count === index) {
					return { ...i, status: false };
				}
				return i;
			});
			setBrandList(newbrandlist);
		} else {
			const newbrandlist = brandlist.map((i = typeof any, count) => {
				if (count === index) {
					return { ...i, status: true };
				}
				return i;
			});
			setBrandList(newbrandlist);
		}
		if (selectedbrandname.some(e => e === brand._id)) {
			const newList = selectedbrandname.filter((item) => item !== brand._id);
			setSelectedBrandName(newList);
		} else {
			setSelectedBrandName(current => [...current, brand._id]);
		}
	}

	// const handleChangeBrand = (e) => {
	// 	let index = 0;
	// 	while (index < selectedBrandlist.length) {
	// 		if (e.target.value === selectedBrandlist[index]) {
	// 			selectedBrandlist.splice(index, 1);
	// 			break;
	// 		}
	// 		index++;
	// 	}
	// 	if (e.target.checked) {
	// 		selectedBrandlist.push(e.target.value)
	// 	}
	// }
	// const handleChangeGen = (e) => {
	// 	if (e.target.checked) {
	// 		setgender(e.target.value)
	// 	}
	// 	else {
	// 		setgender('')
	// 	}
	// }
	const handleChange = (e) => {
		if (e.target.id === "txtFirstName") {
			setfirstName(e.target.value)
			let userpayload = {
				firstname: e.target.value,
			}
			dispatch(user_signin(userpayload));
		}
		else if (e.target.id === "txtLastName") {
			setlastName(e.target.value)
			let userpayload = {
				lastname: e.target.value,
			}
			dispatch(user_signin(userpayload));
		}
		// else if (e.target.id == "email") {
		// 	setemail(e.target.value)
		// }
		else if (e.target.id === "txtAge") {
			setage(e.target.value)
			let userpayload = {
				age: e.target.value,
			}
			dispatch(user_signin(userpayload));
		}
		else if (e.target.id === "male") {
			setgender("Men")
			let userpayload = {
				gender: "Men"
			}
			dispatch(user_signin(userpayload));
		}
		else if (e.target.id === "female") {
			setgender("Women")
			let userpayload = {
				gender: "Women",
			}
			dispatch(user_signin(userpayload));
		}
		else if (e.target.id === "unisex") {
			setgender("Unisex")
			let userpayload = {
				gender: "Unisex",
			}
			dispatch(user_signin(userpayload));
		}
		else if (e.target.id === "USA") {
			let userpayload = {
				COO: ["USA"]
			}
			dispatch(user_signin(userpayload));
			// setCOO("USA")
		}
		else if (e.target.id === "INDIA") {
			let userpayload = {
				COO: ["INDIA"]
			}
			dispatch(user_signin(userpayload));
			// setCOO("INDIA")
		}
	};
	// const handleChange = (e) => {
	// 	if (e.target.id == "txtFirstName") {
	// 		setfirstName(e.target.value)
	// 	}
	// 	else if (e.target.id == "txtLastName") {
	// 		setlastName(e.target.value)
	// 	}
	// 	// else if (e.target.id == "txtEmail") {
	// 	// 	setemail(e.target.value)
	// 	// }
	// 	else if (e.target.id == "txtAge") {
	// 		setage(e.target.value)
	// 	}
	// 	else if (e.target.id == "male") {
	// 		setgender("Male")
	// 	}
	// 	else if (e.target.id == "female") {
	// 		setgender("Female")
	// 	}
	// 	else if (e.target.id == "unisex") {
	// 		setgender("Unisex")
	// 	}
	// 	else if (e.target.id == "INDIA") {
	// 		setCOO("INDIA")
	// 	}
	// 	else if (e.target.id == "USA") {
	// 		setCOO("USA")
	// 	}
	// };
	function get_categories() {
		var userpayload =
		{
			"gender": gender,
			"COO": state_account.profile.COO
		}
		dispatch(get_categroylist_pagination(userpayload, offset, count, (res) => {
			if (res.status === 200) {
				const updatedCategories = res.data.map((category) => ({
					...category,
					status: state_account.profile.category.includes(category._id),
				}));
		
				// Batch update categories to reduce re-renders
				setCategories((current) => [...current, ...updatedCategories]);
			}
		}));
	}
	function get_brands() {
		setBrandList([])
		var brandpayload =
		{
			"gender": gender,
			"COO": state_account.profile.COO,
			"category": selectedCategorylist
		}
		dispatch(get_brandlist_pagination(brandpayload, offset, count, (res) => {
			if (res.status === 200) {
				const updatedBrandList = res.data.map((brand) => {
					const isBrandIncluded = state_account.profile.brand.includes(brand.brandname);
					return {
						_id: brand.brandname,
						image: brand.image,
						status: isBrandIncluded
					};
				});
		
				// Batch update the brand list for better performance
				setBrandList((current) => [...current, ...updatedBrandList]);
		
				// Optional: Check if no data was returned
				if (res.data.length === 0) {
					// setIsEndList(true);
				}
			}
		}));
		
	}
	// function onScroll() {
	// 	if (listInnerRef.current && isendlist === false) {
	// 		const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
	// 		if (scrollTop + clientHeight === scrollHeight) {
	// 			offset = offset + 1;
	// 			get_brands();
	// 		}
	// 	}
	// };


	function search_brands(e) {
		setBrandList([])
		// setIsEndList(false);
		if (e.target.id === "searchBrand") {
			var brandpayload =
			{
				"gender": state_account.profile.gender,
				"COO": state_account.profile.COO,
				"search": e.target.value,
			}
			dispatch(get_brandlist_pagination_by_search(brandpayload, offset, count, (res) => {
				if (res.status === 200) {
					const updatedBrands = res.data.map((brand) => ({
						...brand,
						status: state_account.profile.brand.includes(brand._id),
					}));
			
					// Batch update the brand list to reduce re-renders
					setBrandList((current) => [...current, ...updatedBrands]);
				}
			}));			
		}
	}

	function search_categories(e) {
		setCategories([])
		// setIsEndList(false);
		if (e.target.id === "searchCategory") {
			var categorypayload =
			{
				"gender": state_account.profile.gender,
				"COO": state_account.profile.COO,
				"search": e.target.value,
			}
			dispatch(get_categorylist_pagination_by_search(categorypayload, offset, count, (res) => {
				if (res.status === 200) {
					const updatedCategories = res.data.map((category) => ({
						...category,
						status: state_account.profile.category.includes(category._id),
					}));
			
					// Batch update the categories to reduce re-renders
					setCategories((current) => [...current, ...updatedCategories]);
				}
			}));
			
		}
	}
	// const initSelectedBrand = () => {
	// 	if (state_account.profile.branddata.length > 0) {
	// 		for (let i = 0; i < state_account.profile.branddata.length; i++) {
	// 			let brand = {
	// 				_id: state_account.profile.branddata[i].brandname,
	// 				image: state_account.profile.branddata[i].image,
	// 			}
	// 			selectedbrandnamenew.push(brand._id)
	// 			setSelectedBrands(current => [...current, brand]);
	// 			setSelectedBrandName(current => [...current, brand._id]);
	// 			if (i === (state_account.profile.branddata.length - 1)) {
	// 				get_brands(selectedbrandnamenew)
	// 			}

	// 		}

	// 	} else {
	// 		get_brands(selectedbrandnamenew)
	// 	}

	// }


	return (
		<>
			<div className="login_area bg_login">
				<div className="container">
					{/* <div className="owl-carousel owl-theme onboarding_carousel_4" id="onboarding_carousel4"> */}
						<div id="carousel-example-generic" className="carousel slide" data-interval="false">
                        <div className="carousel-inner home_first_slider banner-data" role="listbox" >

						<div className="item active">
							<div className="row">
								<div className="col-md-1"></div>
								<div className="col-md-4">
									<img src="images/logo.png" alt="" className="login_logo" />
									<div className="login_content">
										<h1 className="h1 text_bold_600">Setup <br />your Profile</h1>
										<div className="form-area mobile_center">
											<ul className="list-unstyled display_flex space_between2">
												<li className="p_big_size">Add your personal details <br /> to setup your profile</li>
											</ul>
										</div>
									</div>
								</div>
								<div className="col-md-1"></div>
								<div className="col-md-5">
									<div className="onborading_brand">
										<div className="onsearch_brand">
											<form>
												<div className="form-area">
													<div className="profile-details-section">
														<div className="avatar-upload">
															<div className="avatar-edit">
																<input type='file' id="imageUpload"
																	accept=".png, .jpg, .jpeg" onChange={upload_profile_image} />
																<label htmlFor="imageUpload"></label>
															</div>
															<div className="avatar-preview">
																<div id="imagePreview">
																	{profileImage!=="" && <img src={profileImage} alt="" className="avatar_img"/>}
																	{profileImage==="" &&<img src='images/userdefault.png' alt="" className="avatar_img"/>}
																</div>
															</div>
														</div>
														<h6>Upload your profile picture</h6>
														<div className="row">
															<div className="col-md-6">
																<div className="name-section">
																	<p>Enter your first name </p>
																</div>
																<div className="onboarding-form-group">
																	<input type="text" id="txtFirstName" className="onboarding-form-control" defaultValue={state_account.profile.firstname} onChange={(e) => handleChange(e)} />
																</div>
															</div>
															<div className="col-md-6">
																<div className="name-section">
																	<p>Enter your last name </p>
																</div>
																<div className="onboarding-form-group">
																	<input type="text" id="txtLastName" className="onboarding-form-control" defaultValue={state_account.profile.lastname} onChange={(e) => handleChange(e)} />
																</div>
															</div>
														</div>
														<div className="email-section">
															<div className="email-id">
																<p>Enter your Email id </p>
															</div>
															<div className="onboarding-form-group">
																<input type="text" id="txtEmail" className="onboarding-form-control" readOnly={true} disabled defaultValue={state_account.profile.email} />
															</div>
														</div>
														<div className="age-section">
															<div className="age">
																<p>Enter your age</p>
															</div>
															<div className="onboarding-form-group">
																<input type="number" id="txtAge" className="onboarding-form-control" defaultValue={state_account.profile.age} onChange={(e) => handleChange(e)} />
															</div>
														</div>
														
														<div className="age-section">
															<div className="col-md-5">
																<div className="age">
																	<p>Country of Origin </p>
																</div>
																<ul className="gender_area display_flex space_between2 list-unstyled">
																	<li>
																		<input type='radio' id='USA' name='COO' onChange={(e) => handleChange(e)} checked={state_account.profile.COO.includes("USA")} />
																		<label htmlFor='USA'>	<div className="brand_circle_title">
																			<img src="/images/onboarding/USA.png" alt="" />
																		</div> USA</label>
																	</li>
																	<li>
																		<input type='radio' id='INDIA' name='COO' onChange={(e) => handleChange(e)} checked={state_account.profile.COO.includes("INDIA")} />
																		<label htmlFor='INDIA'>	<div className="brand_circle_title">
																			<img src="/images/onboarding/INDIA.png" alt="" />
																		</div> INDIA</label>
																	</li>
																</ul>
															</div>
															<div className="col-md-7">
																<div className="age">
																	<p>Gender</p>
																</div>
																<ul className="gender_area display_flex space_between2 list-unstyled">
																	<li>
																		{/* <input type='radio' id='male' name='gender' value={"Men"} onChange={(e) => handleChangeGen(e)} /> */}
																		<input type='radio' id='male' name='gender' onChange={(e) => handleChange(e)} checked={gender === "Men" ? true : false} />

																		<label htmlFor='male'>	<div className="brand_circle_title">
																			<img src="/images/onboarding/man.png" alt="" />
																		</div> Male</label>
																	</li>
																	<li>
																		{/* <input type='radio' id='female' name='gender' value={"Women"} onChange={(e) => handleChangeGen(e)} /> */}
																		<input type='radio' id='female' name='gender' onChange={(e) => handleChange(e)} checked={gender === "Women" ? true : false} />
																		<label htmlFor='female'><div className="brand_circle_title">
																			<img src="/images/onboarding/woman.png" alt="" />
																		</div> Female</label>
																	</li>
																	<li>
																		{/* <input type='radio' id='unisex' name='gender' value={"Unisex"} onChange={(e) => handleChangeGen(e)} /> */}
																		<input type='radio' id='unisex' name='gender' onChange={(e) => handleChange(e)} checked={gender === "Unisex" ? true : false} />
																		<label htmlFor='unisex'>
																			<div className="brand_circle_title">
																				<img src="/images/onboarding/transgender.png" alt="" />
																			</div> Other</label>
																	</li>
																</ul>
															</div>
														</div>
														<button type="button" className="get_otp owl-carousel__next" href="#carousel-example-generic" role="button" data-slide="next" onClick={() => {update_user_store_profile()}}>CONTINUE</button>
													</div>
												</div>
											</form>
										</div>
									</div>
								</div>
								<div className="col-md-1"></div>
							</div>
						</div>
						<div className="item">
							<div className="row">
								<div className="col-md-1"></div>
								<div className="col-md-4">
									<img src="images/logo.png" alt="" className="login_logo" />
									<div className="login_content">
										<h1 className="h1 text_bold_600">Hello,<span className="">Welcome to Jesssu</span></h1>
										<p className="text-bold-black">Just Earn through Shop, Share, and Stash</p>
										<div className="form-area mobile_center">
											<ul className="list-unstyled display_flex space_between2">
												<li className="p_big_size">Shop the latest and trending collection of fashion products</li>
											</ul>
										</div>
									</div>
								</div>
								<div className="col-md-1"></div>
								<div className="col-md-5">
									<div className="onborading_brand">
										<div className="onsearch_brand">
											<form>
												<div className="form-area">
													<div className="brand_search_area">
														<i className="fa-solid fa-magnifying-glass"></i>
														<input type="text" placeholder="Search categories" id="searchCategory" onChange={(e) => search_categories(e)} />
													</div>
													<div className="onsearch_brand_content">
														{/* <div className="row">
															{categories.map((category, i) => {
																return (
																	<div className="select_brand_area brand_1 col-md-4 col-xs-6" key={i}>
																		<input className="checkbox-btn" name="checkbox-collection" value={category._id}
																			id={`0-4${i}`} type="checkbox" onClick={(e) => handleChangeCat(e)} />
																		<label className="checkbox-label" htmlFor={`0-4${i}`}>
																			<div className="itemcontent">
																				<div className="brand_circle_title">
																					<img src={category.image}></img>
																				</div>
																			</div>
																		</label>
																		<p className="brand_1_heading" data-tip data-for={`brandtip${i}`}>{category._id.substring(0, 7)}...</p>
																		<ReactTooltip id={`brandtip${i}`} place="top" effect="solid">{category._id}</ReactTooltip>
																	</div>)})}
														</div> */}

														<div className="row">
															{categories.map((category, i) => (
																<div className="brand_1 col-md-4 col-xs-6" key={i}>
																	{category.status === false &&
																		<input className="checkbox-btn" name="checkbox-collection" value={category._id}
																			id={i} type="checkbox" onChange={() => { selectCategory(category, i) }} />}
																	{category.status === true &&
																		<input className="checkbox-btn" name="checkbox-collection" value={category._id}
																			id={i} type="checkbox" onChange={() => { selectCategory(category, i) }} checked={true} />}
																	<label className="checkbox-label" htmlFor={i}>
																		<div className="itemcontent">
																			<div className="brand_circle_title">
																				{category.image && /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(category.image) ? <img src={category.image} alt=""/> : <img src="/images/defultimg.svg" className="defult_icon" alt=""/>}
																			</div>
																		</div>
																	</label>
																	<p className="brand_1_heading" data-tip data-for={`categorytip${i}`}>{category._id !== null && category._id !== undefined ? category._id.substring(0, 7) : ""}...</p>
																	<ReactTooltip id={`categorytip${i}`} place="top" effect="solid">{category._id}</ReactTooltip>
																</div>))}
														</div>
													</div>
													<button type="button" href="#carousel-example-generic" role="button" data-slide="next" className="get_otp owl-carousel__next" onClick={() => update_user_store_category()}>Next</button>
												</div>
											</form>
										</div>
									</div>
								</div>
								<div className="col-md-1"></div>
							</div>
						</div>
						<div className="item">
							<div className="row">
								<div className="col-md-1"></div>
								<div className="col-md-4">
									<img src="images/logo.png" alt="" className="login_logo" />
									<div className="login_content">
										<h1 className="h1 text_bold_600">Hello,<span className="">Welcome to Jesssu</span></h1>
										<p className="text-bold-black">Just Earn through Shop, Share, and Stash</p>
										<div className="form-area mobile_center">
											<ul className="list-unstyled display_flex space_between2">
												<li className="p_big_size">Shop the latest and trending collection of fashion products</li>
											</ul>
										</div>
									</div>
								</div>
								<div className="col-md-1"></div>
								<div className="col-md-5">
									<div className="onborading_brand">
										<div className="onsearch_brand brand_img_2">
											<form>
												<div className="form-area">
													<div className="brand_search_area">
														<i className="fa-solid fa-magnifying-glass"></i>
														<input placeholder="Search brands" id="searchBrand" onChange={(e) => search_brands(e)} />
														{/* <input type="text" placeholder="Search brands" /> */}
													</div>
													<div className="onsearch_brand_content">
														{/* <div className="display_flex space_between2"> */}
														{/* <div className="classic_height" ref={listInnerRef}>
																<div className="brand_1_area"> */}
														{/* <div className="row" >
																		{brandlist.map((brand, i) => {
																			return (
																				<div className="select_brand_area brand_1 col-md-4 col-xs-6" key={i}>
																					<input className="checkbox-btn" name="checkbox-collection" value={brand.brandname}
																						id={`0-4brand${i}`} type="checkbox" onClick={(e) => handleChangeBrand(e)} />
																					<label className="checkbox-label" htmlFor={`0-4brand${i}`}>
																						<div className="itemcontent">
																							<div className="brand_circle_title">
																								<img src={brand.image}></img>
																							</div>
																						</div>
																					</label>
																					<p className="brand_1_heading" data-tip data-for={`brandtip${i}`}>{brand.brandname.substring(0, 7)}...</p>
																					<ReactTooltip id={`brandtip${i}`} place="top" effect="solid">{brand.brandname}</ReactTooltip>

																				</div>
																			)
																		})}

																	</div> */}

														<div className="row" >
															{brandlist.map((brand, i) => (
																<div className="brand_1 col-md-4 col-xs-6" key={i}>
																	{/* {brand.status === false && */}
																	<input className="brand_checkbox_btn checkbox-btn" name="checkbox-collection" value={brand._id}
																		id={i} type="checkbox" onChange={() => { selectBrand(brand, i) }} checked={brand.status} />
																	{/* } */}
																	{/* {brand.status === true &&
																					<input className="checkbox-btn" name="checkbox-collection" value={brand._id}
																						id={i} type="checkbox" onChange={() => { selectBrand(brand, i) }} checked={true} />} */}
																	<label className="checkbox-label" htmlFor={i}>
																		<div className="itemcontent">
																			<div>
																				{brand.image && /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(brand.image) ? <img src={brand.image} alt=""/> : <img src="/images/defultimg.svg" className="defult_icon" alt=""/>}

																				{/* {brand.image == "" ? <img src="/images/defultimg.svg" /> : <img src={brand.image} />} */}
																			</div>
																		</div>
																	</label>
																	<p className="brand_1_heading" data-tip data-for={`brandtip${i}`}>{brand._id !== null && brand._id !== undefined ? brand._id.substring(0, 7) : ""}...</p>
																	<ReactTooltip id={`brandtip${i}`} place="top" effect="solid">{brand._id}</ReactTooltip>
																</div>
															)
															)
															}
														</div>
														{/* </div>
															</div> */}

														{/* </div> */}

													</div>
													<button type="button" className="get_otp" onClick={() => update_user_store_brand()}>Next</button>
												</div>
											</form>
										</div>
									</div>
								</div>
								<div className="col-md-1"></div>
							</div>




						</div>
						</div>
                        {/* <a className="right carousel-control" href="#carousel-example-generic" role="button" data-slide="next"> <span className="glyphicon glyphicon-chevron-left fa-solid fa-arrow-right-long" aria-hidden="true"></span> <span className="sr-only">Previous</span> </a>
                        <a className="left carousel-control" href="#carousel-example-generic" role="button" data-slide="prev"> <span className="glyphicon glyphicon-chevron-right fa-solid fa-arrow-left-long" aria-hidden="true"></span> <span className="sr-only">Next</span> </a> */}
                    </div>
					{/* </div> */}
				</div>
			</div>

			{/* <ScriptTag type="text/javascript"
				src=
				"/owlcarousel/owl.carousel.min.js" /> */}
			{carousel === true ? (
				<></>
				// <ScriptTag type="text/javascript"
				// 	src=
				// 	"/js/onboarding4_carousel.js" />
			) : <></>}
		</>
	)
}

export default Onboarding2;