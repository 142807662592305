/* eslint-disable */
import React, { useEffect, useState } from "react";
import { get_wishlist, get_look_background, create_new_look, edit_my_look } from "../../store/stash/api"
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { notification_message_status } from "../../store/account/accountActions"
import { useDispatch, useSelector } from "react-redux";
import { Footer, Header, Mylook, ProfileSideBar, UserProductCard, Wishlist } from 'components';
import { AIAgent } from 'genai-product-comparison-agent';

// import { get_user_profile_details } from "store/account/api";
// import { user_signin } from "store/account/accountActions";
import { Helmet } from 'react-helmet';
import { LazyLoadImage } from 'react-lazy-load-image-component';
const CreateLook = () => {
    const location = useLocation();
    const history = useNavigate();
    const dispatch = useDispatch();
    // const [productlist, setProductList] = useState([]);
    const [grouplist, setGroupList] = useState([]);
    const [backgroundlist, setBackgroundList] = useState([]);
    const [selectedprodcts, setSelectedProducts] = useState([]);
    const [lookname, setLookName] = useState('');
    const [bg, setBG] = useState('');
    const [layout, setLayout] = useState('3-2');
    const { id } = useParams();
    const state_account = useSelector(state => state.account);
    const [showLimitModal, setShowLimitModal] = useState(false);
    const [showEmptyNameModal, setShowEmptyNameModal] = useState(false);

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            set_look_value();
            get_wishlist_products();
            get_Look_background();
        }
        return () => { isMounted = false };
    }, []);
    // console.log(state_account.profile.lookimages)

    const set_look_value = () => {
        if (id !== undefined) {
            setSelectedProducts(location.state.bundledetails.productdetails);
            setLookName(location.state.bundledetails.bundlename);
            setBG(location.state.bundledetails.backgroundimage);
            setLayout(location.state.bundledetails.layout);
        }
    }
    const namevalueChange = event => {
        setLookName(event.target.value);
    };
    const changeBG = (image) => {
        setBG(image);
    };

    const create_look = () => {
        if (!lookname.trim()) {
            setShowEmptyNameModal(true);
            return;
        }

        let userpayload =
        {
            "email": state_account.profile.email,
            "token": state_account.profile.token,
            "bundlename": lookname,
            "bundleamount": 0,
            "productdetails": selectedprodcts,
            "backgroundimage": bg,
            "layout": layout
        }
        dispatch(create_new_look(userpayload, (res) => {
            if (res.status === 200) {
                history('/stash');
            }
        }));
    }

    const edit_look = () => {
        if (!lookname.trim()) {
            setShowEmptyNameModal(true);
            return;
        }

        let userpayload =
        {
            "bundleid": id,
            "email": state_account.profile.email,
            "token": state_account.profile.token,
            "bundlename": lookname,
            "bundleamount": 0,
            "productdetails": selectedprodcts,
            "backgroundimage": bg,
            "layout": layout
        }
        dispatch(edit_my_look(userpayload, (res) => {
            if (res.status === 200) {
                history('/stash');
            }
        }));
    }
    const get_wishlist_products = () => {
        let userpayload =
        {
            "email": state_account.profile.email,
            "token": state_account.profile.token,
        }
        dispatch(get_wishlist(userpayload, (res) => {
            if (res.status === 200) {
                // setProductList(res.data);
                const groupedTech = res.data.reduce((acc, value) => {
                    if (!acc[value.category_name[0]]) {
                        acc[value.category_name[0]] = []
                    }
                    acc[value.category_name[0]].push(value)
                    return acc
                }, {})
                const resdata = Object.entries(groupedTech).map(([category, data]) => ({
                    category,
                    data
                }))
                setGroupList(resdata)
            }
        }));
    }
    const get_Look_background = () => {
        let userpayload =
        {
            "email": state_account.profile.email,
            "token": state_account.profile.token,
        }
        dispatch(get_look_background(userpayload, (res) => {
            if (res.status === 200) {
                setBackgroundList(res.data);
            }
        }));
    }
    const setSelectedProduct = (i, sequence) => {
        if (selectedprodcts.some(e => e.productid === i.item_id[0])) {
            const newList = selectedprodcts.filter((item) => item.productid !== i.item_id[0]);
            setSelectedProducts(newList);
        } else {
            if (selectedprodcts.length >= 6) {
                setShowLimitModal(true);
                return;
            }
            let productpayload =
            {
                "productid": i.item_id[0],
                "productimage": i.other_image_url[0],
                "productname": i.item_name[0],
                "productprice": 0,
                "sequence": sequence
            }
            setSelectedProducts(current => [...current, productpayload]);
        }
    }
    // const setSelectedProductByImage = (i, sequence) => {
    //     if (selectedprodcts.some(e => e.productimage == i)) {
    //         const newList = selectedprodcts.filter((item) => item.productimage != i);
    //         setSelectedProducts(newList);
    //     } else {
    //         let productpayload =
    //         {
    //             "productid": "",
    //             "productimage": i,
    //             "productname": "",
    //             "productprice": 0,
    //             "sequence": sequence
    //         }
    //         setSelectedProducts(current => [...current, productpayload]);
    //     }
    // }
    // const delete_image = (e) => {
    //     let payload = {
    //         "email": state_account.profile.email,
    //         "image": e
    //     }
    //     delete_profile_look_image(payload, (res) => {
    //         if (res.success === true) {
    //             get_profile_details()
    //         }
    //     })
    // }
    // const get_profile_details = () => {
    //     let udata = [];
    //     let payload = {
    //         "token": state_account.profile.token,
    //         "email": state_account.profile.email,
    //     }
    //     dispatch(get_user_profile_details(payload, (res) => {

    //         if (res.status === 200) {
    //             udata = res.data;
    //             dispatch(user_signin(udata));
    //         }
    //     }));
    // }
    // const upload_image = (e) => {
    //     if (e) {
    //         const formData = new FormData();
    //         formData.append(
    //             "file",
    //             e.target.files[0]
    //         );
    //         let COO = state_account.profile.COO.length == 0 ? "USA" : state_account.profile.COO[0];
    //         let email = state_account.profile.email;
    //         jesssu_image_upload_by_email(formData, COO, email, (res) => {

    //             if (res.success === true) {
    //                 get_profile_details()
    //             }
    //         })
    //     }
    // }

    function changeLayout(e) {

        if (e === "fourfourlabel") {
            setLayout("4-4")
        }
        else if (e === "twotwoonelabel") {
            setLayout("2-2-1")
        }
        else if (e === "threetwolabel") {
            setLayout("3-2")
        }
    }

    const [currentSection, setCurrentSection] = useState('wishlist'); // State to track the current section

    // Function to handle the "Previous" button click
    const handlePrevious = () => {
        if (currentSection === 'background') {
            setCurrentSection('wishlist');
        } else if (currentSection === 'layout') {
            setCurrentSection('background');
        }
    };

    // Function to handle the "Next" button click
    const handleNext = () => {
        if (currentSection === 'wishlist') {
            setCurrentSection('background');
        } else if (currentSection === 'background') {
            setCurrentSection('layout');
        }
    };

    const scrollbarStyles = `
        .wishlist-scrollable-container::-webkit-scrollbar {
            width: 8px;
        }
        
        .wishlist-scrollable-container::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 10px;
            margin: 4px;
        }
        
        .wishlist-scrollable-container::-webkit-scrollbar-thumb {
            background: #FFCA37;
            border-radius: 10px;
        }
        
        .wishlist-scrollable-container::-webkit-scrollbar-thumb:hover {
            background: #e6b632;
        }
        
        .wishlist-scrollable-container {
            scrollbar-width: thin;
            scrollbar-color: #FFCA37 #f1f1f1;
        }
    `;

    return (
        <>
            {/* Product Limit Modal */}
            {showLimitModal && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 1000
                }}>
                    <div style={{
                        backgroundColor: 'white',
                        padding: '20px',
                        borderRadius: '10px',
                        maxWidth: '400px',
                        width: '90%',
                        textAlign: 'center',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
                    }}>
                        <h4 style={{ marginBottom: '15px', color: '#333' }}>Product Limit Reached</h4>
                        <p style={{ marginBottom: '20px', color: '#666' }}>
                            You can select a maximum of 6 products for your look. Please remove some products to add more.
                        </p>
                        <button 
                            onClick={() => setShowLimitModal(false)}
                            style={{
                                backgroundColor: '#FFCA37',
                                border: 'none',
                                padding: '10px 20px',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                fontWeight: 'bold'
                            }}
                        >
                            Got it
                        </button>
                    </div>
                </div>
            )}
            
            {/* Empty Name Modal */}
            {showEmptyNameModal && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 1000
                }}>
                    <div style={{
                        backgroundColor: 'white',
                        padding: '20px',
                        borderRadius: '10px',
                        maxWidth: '400px',
                        width: '90%',
                        textAlign: 'center',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
                    }}>
                        <h4 style={{ marginBottom: '15px', color: '#333' }}>Look Name Required</h4>
                        <p style={{ marginBottom: '20px', color: '#666' }}>
                            Please enter a name for your look before saving.
                        </p>
                        <button 
                            onClick={() => setShowEmptyNameModal(false)}
                            style={{
                                backgroundColor: '#FFCA37',
                                border: 'none',
                                padding: '10px 20px',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                fontWeight: 'bold'
                            }}
                        >
                            Got it
                        </button>
                    </div>
                </div>
            )}
            
            <div className="thetop"></div>
            {/* <!-- Navigation Menu --> */}
            <Header />
            <Helmet>
                <title>Create Your Look : Customize & Share Your Unique Style</title>
                <meta name="description" content="Design your perfect outfit by combining shirts, pants, shoes, and more. Create a stylish collage, showcase your unique look, and share it with friends." />
                <meta name="keywords" content="Create your look, customize, unique" />
            </Helmet>
            {/* <!-- Navigation Menu closed Here -->
            <!-- Stash My Look Area Starts Here--> */}
            {/* <div className="container img_upload_section">
                <div className="dotted-border col-md-5">
                    <i className="fa-solid fa-upload upload-icon"></i>
                    <p className="upload-text">Upload images from your device to create your look</p>
                    <div className="image-upload-box1">
                        <input type="file" id="img01" accept=".png, .jpg, .jpeg" onChange={(e) => { upload_image(e) }} />
                        <label htmlFor="img01" className="upload-imgbox1">
                            <button type="button" className="upload-own-image-button"
                            >Choose file</button>
                        </label>
                    </div>
                </div>
                <div className="col-md-7">
                    <div className="select_createnewlook_area">
                        {(state_account.profile.lookimages != undefined && state_account.profile.lookimages.length > 0) &&
                            state_account.profile.lookimages.map((i, key) =>
                            (<><div className="col-md-4" key={key}>
                                <div className="uploaded-image-box" >
                                    <a className="trash-image-icon" onClick={() => { delete_image(i) }}><i className="fa-solid fa-trash "></i></a>
                                    <LazyLoadImage className={`${selectedprodcts.some((e) => e.productimage == i) ? "uploaded-img-main selected_section" : "uploaded-img-main"}`} src={`${i}`} onClick={() => { setSelectedProductByImage(i, key) }} />
                                </div>
                            </div>
                            </>))}
                    </div>
                </div>
            </div> */}



            <div className="stash_my_look_area">
                <div className="container">
                    <div className="row" style={{
                        flexWrap: 'wrap',
                        gap: '20px',
                        margin: '0'
                    }}>
                        <ProfileSideBar />
                        <div style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: '20px',
                            flex: '1',
                            minWidth: '280px'
                        }}>
                            <div
                                className="col-md-6"
                                style={{
                                    flex: '1',
                                    minWidth: '280px',
                                    backgroundColor: 'white',
                                    height: 'auto',
                                    minHeight: '500px',
                                    padding: '20px',
                                    borderRadius: '10px',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                    position: 'relative',
                                }}
                            >
                                <div style={{
                                    height: 'calc(100% - 60px)',
                                    overflowY: 'auto',
                                    paddingBottom: '20px'
                                }}>
                                    {/* Wishlist Section */}
                                    {currentSection === 'wishlist' && (
                                        <div className="list_section your_wishlist_section">
                                            <h3 style={{
                                                fontSize: '24px',
                                                fontWeight: '600',
                                                color: '#333',
                                                marginBottom: '8px'
                                            }}>Your Wishlist</h3>
                                            <h5>Select images you want to create your look of</h5>
                                            {grouplist.length === 0 ? (
                                                <LazyLoadImage src='images/wishlist default.svg' alt="Wishlist icon" style={{ maxWidth: '100%', height: 'auto' }} />
                                            ) : (
                                                <div
                                                    className="wishlist-scrollable-container"
                                                    style={{
                                                       
                                                        height: '300px',
                                                        overflowY: 'auto',
                                                        borderRadius: '10px',
                                                        padding: '6px 12px 6px 6px',
                                                        position: 'relative'
                                                    }}
                                                >
                                                    <div style={{
                                                        position: 'absolute',
                                                        bottom: 0,
                                                        left: 0,
                                                        right: 0,
                                                        height: '40px',
                                                        background: 'linear-gradient(transparent, rgba(255,255,255,0.9))',
                                                        pointerEvents: 'none',
                                                        zIndex: 1,
                                                        borderBottomLeftRadius: '10px',
                                                        borderBottomRightRadius: '10px'
                                                    }}></div>
                                                    {grouplist.map((data, ckey) => (
                                                        <div key={ckey} style={{ padding: 0, marginBottom: '20px' }}>
                                                            <h4>{data.category}</h4>
                                                            <div
                                                                style={{
                                                                    display: 'grid',
                                                                    gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr))',
                                                                    gap: '10px',
                                                                }}
                                                            >
                                                                {data.data.map((product, pkey) => (
                                                                    <div
                                                                        key={pkey}
                                                                        style={{
                                                                            padding: 0,
                                                                            boxSizing: 'border-box',
                                                                        }}
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                cursor: 'pointer',
                                                                                border: selectedprodcts.some((e) => e.productid === product.item_id[0])
                                                                                    ? '2px solid #FFCA37'
                                                                                    : '1px solid #ddd',
                                                                                padding: '5px',
                                                                                borderRadius: '5px',
                                                                                overflow: 'hidden',
                                                                            }}
                                                                            onClick={() => setSelectedProduct(product, pkey)}
                                                                        >
                                                                            <LazyLoadImage
                                                                                src={product.other_image_url[0]}
                                                                                alt="User-wishlisted product image"
                                                                                style={{
                                                                                    width: '100%',
                                                                                    aspectRatio: '1',
                                                                                    objectFit: 'cover',
                                                                                    borderRadius: '5px',
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    )}

                                    {/* Background Section */}
                                    {currentSection === 'background' && (
                                        <div className="">
                                            <h3 style={{
                                                fontSize: '24px',
                                                fontWeight: '600',
                                                color: '#333',
                                                marginBottom: '8px'
                                            }}>Select Background</h3>
                                            <h5>Select a background image for your look</h5>
                                            <div
                                                style={{
                                                    padding: 0,
                                                    height: '300px',
                                                    overflowY: 'auto',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: 'grid',
                                                        gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr))',
                                                        gap: '10px',
                                                    }}
                                                >
                                                    {backgroundlist.length === 0 ? (
                                                        <></>
                                                    ) : (
                                                        backgroundlist.map((i, key) => (
                                                            <div
                                                                key={key}
                                                                style={{
                                                                    padding: 0,
                                                                    boxSizing: 'border-box',
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                        border: bg === i.imageurl ? '2px solid blue' : '1px solid #ddd',
                                                                        padding: '5px',
                                                                        borderRadius: '5px',
                                                                        overflow: 'hidden',
                                                                    }}
                                                                    onClick={() => changeBG(i.imageurl)}
                                                                >
                                                                    <LazyLoadImage
                                                                        src={i.imageurl}
                                                                        alt="My look background image"
                                                                        style={{
                                                                            width: '100%',
                                                                            aspectRatio: '1',
                                                                            objectFit: 'cover',
                                                                            borderRadius: '5px',
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        ))
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {/* Layout Section */}
                                    {currentSection === 'layout' && (
                                        <div className="layout-selection-container" style={{
                                            padding: '20px',
                                            paddingBottom: '80px',
                                            position: 'relative',
                                            height: '500px',
                                            overflowY: 'auto'
                                        }}>
                                            <div style={{
                                                marginBottom: '24px',
                                                borderBottom: '1px solid #eee',
                                                paddingBottom: '16px'
                                            }}>
                                                <h3 style={{
                                                    fontSize: '24px',
                                                    fontWeight: '600',
                                                    color: '#333',
                                                    marginBottom: '8px'
                                                }}>Select Layout</h3>
                                                <p style={{
                                                    fontSize: '16px',
                                                    color: '#666',
                                                    margin: 0
                                                }}>Choose how you want to arrange your products</p>
                                            </div>

                                            

                                            {selectedprodcts.length < 6 ? (
                                                <div style={{
                                                    textAlign: 'center',
                                                    padding: '40px 20px',
                                                    backgroundColor: '#f8f9fa',
                                                    borderRadius: '8px',
                                                    margin: '20px 0'
                                                }}>
                                                    <img
                                                        src='images/layout default2.svg'
                                                        alt="Layout selection"
                                                        style={{
                                                            width: '120px',
                                                            marginBottom: '20px'
                                                        }}
                                                    />
                                                    <p style={{
                                                        color: '#666',
                                                        fontSize: '16px',
                                                        marginBottom: '16px'
                                                    }}>
                                                        Please select at least 6 products to use layouts
                                                    </p>
                                                    <button style={{
                                                        backgroundColor: '#FFCA37',
                                                        color: '#333',
                                                        border: 'none',
                                                        padding: '10px 24px',
                                                        borderRadius: '6px',
                                                        fontWeight: '500',
                                                        cursor: 'pointer'
                                                    }} onClick={() => setCurrentSection('wishlist')}>
                                                        Select Products
                                                    </button>
                                                </div>
                                            ) : (
                                                <div style={{
                                                    display: 'flex',
                                                    flexWrap: 'wrap', // Ensure items wrap to the next line on smaller screens
                                                    justifyContent: 'space-between',
                                                    marginBottom: '32px',
                                                   
                                                    background: '#f8f9fa',
                                                    borderRadius: '12px',
                                                    boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                                                    '@media (max-width: 768px)': { // Media query for mobile view
                                                        flexDirection: 'column', // Stack items vertically
                                                        alignItems: 'center', // Center items horizontally
                                                    }
                                                }}>
                                                    {/* Layout 1 Station - 4x4 */}
                                                    <div 
                                                        onClick={() => changeLayout("fourfourlabel")}
                                                        style={{
                                                            flex: 1,
                                                            textAlign: 'center',
                                                            padding: '10px',
                                                            borderRight: '1px solid #dee2e6',
                                                            cursor: 'pointer',
                                                            '@media (max-width: 768px)': { // Media query for mobile view
                                                                borderRight: 'none', // Remove right border on mobile
                                                                borderBottom: '1px solid #dee2e6', // Add bottom border for separation
                                                                width: '100%', // Take full width
                                                            }
                                                        }}
                                                    >
                                                        <div style={{
                                                            width: '100px',
                                                            height: '100px',
                                                            margin: '0 auto 12px',
                                                            background: '#fff',
                                                            borderRadius: '8px',
                                                            padding: '12px',
                                                            boxShadow: layout === "4-4" ? '0 0 0 2px #FFCA37' : '0 2px 4px rgba(0,0,0,0.1)',
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            gap: '8px'
                                                        }}>
                                                            {/* Top Row */}
                                                            <div style={{
                                                                display: 'flex',
                                                                gap: '8px',
                                                                height: '45%'
                                                            }}>
                                                                <div style={{
                                                                    flex: 1,
                                                                    background: layout === "4-4" ? '#FFCA37' : '#E9ECEF',
                                                                    borderRadius: '4px'
                                                                }}></div>
                                                                <div style={{
                                                                    flex: 1,
                                                                    background: layout === "4-4" ? '#FFCA37' : '#E9ECEF',
                                                                    borderRadius: '4px'
                                                                }}></div>
                                                            </div>
                                                            {/* Bottom Row */}
                                                            <div style={{
                                                                display: 'flex',
                                                                gap: '8px',
                                                                height: '45%'
                                                            }}>
                                                                <div style={{
                                                                    flex: 1,
                                                                    background: layout === "4-4" ? '#FFCA37' : '#E9ECEF',
                                                                    borderRadius: '4px'
                                                                }}></div>
                                                                <div style={{
                                                                    flex: 1,
                                                                    background: layout === "4-4" ? '#FFCA37' : '#E9ECEF',
                                                                    borderRadius: '4px'
                                                                }}></div>
                                                            </div>
                                                        </div>
                                                        <h4 style={{
                                                            fontSize: '16px',
                                                            fontWeight: '600',
                                                            color: layout === "4-4" ? '#FFCA37' : '#333',
                                                            marginBottom: '4px'
                                                        }}>Layout 1</h4>
                                                        
                                                    </div>
                                                
                                                    {/* Layout 2 Station - 2-2-1 */}
                                                    <div 
                                                        onClick={() => changeLayout("twotwoonelabel")}
                                                        style={{
                                                            flex: 1,
                                                            textAlign: 'center',
                                                            padding: '10px',
                                                            borderRight: '1px solid #dee2e6',
                                                            cursor: 'pointer',
                                                            '@media (max-width: 768px)': { // Media query for mobile view
                                                                borderRight: 'none', // Remove right border on mobile
                                                                borderBottom: '1px solid #dee2e6', // Add bottom border for separation
                                                                width: '100%', // Take full width
                                                            }
                                                        }}
                                                    >
                                                        <div style={{
                                                            width: '100px',
                                                            height: '100px',
                                                            margin: '0 auto 12px',
                                                            background: '#fff',
                                                            borderRadius: '8px',
                                                            padding: '12px',
                                                            boxShadow: layout === "2-2-1" ? '0 0 0 2px #FFCA37' : '0 2px 4px rgba(0,0,0,0.1)',
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            gap: '4px'
                                                        }}>
                                                            {/* Top Row */}
                                                            <div style={{
                                                                display: 'flex',
                                                                gap: '8px',
                                                                height: '30%'
                                                            }}>
                                                                <div style={{
                                                                    flex: 1,
                                                                    background: layout === "2-2-1" ? '#FFCA37' : '#E9ECEF',
                                                                    borderRadius: '4px'
                                                                }}></div>
                                                                <div style={{
                                                                    flex: 1,
                                                                    background: layout === "2-2-1" ? '#FFCA37' : '#E9ECEF',
                                                                    borderRadius: '4px'
                                                                }}></div>
                                                            </div>
                                                            {/* Middle Row */}
                                                            <div style={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                height: '30%'
                                                            }}>
                                                                <div style={{
                                                                    width: '50%',
                                                                    background: layout === "2-2-1" ? '#FFCA37' : '#E9ECEF',
                                                                    borderRadius: '4px'
                                                                }}></div>
                                                            </div>
                                                            {/* Bottom Row */}
                                                            <div style={{
                                                                display: 'flex',
                                                                gap: '8px',
                                                                height: '30%'
                                                            }}>
                                                                <div style={{
                                                                    flex: 1,
                                                                    background: layout === "2-2-1" ? '#FFCA37' : '#E9ECEF',
                                                                    borderRadius: '4px'
                                                                }}></div>
                                                                <div style={{
                                                                    flex: 1,
                                                                    background: layout === "2-2-1" ? '#FFCA37' : '#E9ECEF',
                                                                    borderRadius: '4px'
                                                                }}></div>
                                                            </div>
                                                        </div>
                                                        <h4 style={{
                                                            fontSize: '16px',
                                                            fontWeight: '600',
                                                            color: layout === "2-2-1" ? '#FFCA37' : '#333',
                                                            marginBottom: '4px'
                                                        }}>Layout 2</h4>
                                                        
                                                    </div>
                                                
                                                    {/* Layout 3 Station - 3-2 */}
                                                    <div 
                                                        onClick={() => changeLayout("threetwolabel")}
                                                        style={{
                                                            flex: 1,
                                                            textAlign: 'center',
                                                            padding: '10px',
                                                            cursor: 'pointer',
                                                            '@media (max-width: 768px)': { // Media query for mobile view
                                                                width: '100%', // Take full width
                                                            }
                                                        }}
                                                    >
                                                        <div style={{
                                                            width: '100px',
                                                            height: '100px',
                                                            margin: '0 auto 12px',
                                                            background: '#fff',
                                                            borderRadius: '8px',
                                                            padding: '12px',
                                                            boxShadow: layout === "3-2" ? '0 0 0 2px #FFCA37' : '0 2px 4px rgba(0,0,0,0.1)',
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            gap: '4px'
                                                        }}>
                                                            {/* Top Row */}
                                                            <div style={{
                                                                display: 'flex',
                                                                gap: '8px',
                                                                height: '32%'
                                                            }}>
                                                                <div style={{
                                                                    flex: 1,
                                                                    background: layout === "3-2" ? '#FFCA37' : '#E9ECEF',
                                                                    borderRadius: '4px'
                                                                }}></div>
                                                                <div style={{
                                                                    flex: 1,
                                                                    background: layout === "3-2" ? '#FFCA37' : '#E9ECEF',
                                                                    borderRadius: '4px'
                                                                }}></div>
                                                            </div>
                                                            {/* Middle Row */}
                                                            <div style={{
                                                                display: 'flex',
                                                                gap: '8px',
                                                                height: '32%'
                                                            }}>
                                                                <div style={{
                                                                    flex: 1,
                                                                    background: layout === "3-2" ? '#FFCA37' : '#E9ECEF',
                                                                    borderRadius: '4px'
                                                                }}></div>
                                                                <div style={{
                                                                    flex: 1,
                                                                    background: layout === "3-2" ? '#FFCA37' : '#E9ECEF',
                                                                    borderRadius: '4px'
                                                                }}></div>
                                                            </div>
                                                            {/* Bottom Row */}
                                                            <div style={{
                                                                display: 'flex',
                                                                gap: '8px',
                                                                height: '32%'
                                                            }}>
                                                                <div style={{
                                                                    flex: 1,
                                                                    background: layout === "3-2" ? '#FFCA37' : '#E9ECEF',
                                                                    borderRadius: '4px'
                                                                }}></div>
                                                                <div style={{
                                                                    flex: 1,
                                                                    background: layout === "3-2" ? '#FFCA37' : '#E9ECEF',
                                                                    borderRadius: '4px'
                                                                }}></div>
                                                            </div>
                                                        </div>
                                                        <h4 style={{
                                                            fontSize: '16px',
                                                            fontWeight: '600',
                                                            color: layout === "3-2" ? '#FFCA37' : '#333',
                                                            marginBottom: '4px'
                                                        }}>Layout 3</h4>
                                                       
                                                    </div>
                                                </div>
                                            )}


                                        </div>
                                    )}

                                    {/* Navigation buttons - Fixed position */}
                                    <div style={{
                                        position: 'absolute',
                                        bottom: '20px',
                                        left: '20px',
                                        right: '20px',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        backgroundColor: 'white',
                                        padding: '10px 0',
                                        zIndex: 10
                                    }}>
                                        {currentSection !== 'wishlist' && (
                                            <button
                                                style={{
                                                    backgroundColor: '#6c757d',
                                                    color: 'white',
                                                    padding: '10px 20px',
                                                    borderRadius: '8px',
                                                    border: 'none',
                                                    cursor: 'pointer',
                                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                                                    fontWeight: 'bold'
                                                }}
                                                onClick={handlePrevious}
                                            >
                                                Previous
                                            </button>
                                        )}
                                        {currentSection !== 'layout' && (
                                            <button
                                                style={{
                                                    marginLeft: 'auto',
                                                    backgroundColor: '#FFCA37',
                                                    color: 'black',
                                                    padding: '10px 20px',
                                                    borderRadius: '8px',
                                                    border: 'none',
                                                    cursor: 'pointer',
                                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                                                    fontWeight: 'bold'
                                                }}
                                                onClick={handleNext}
                                            >
                                                Next
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div
                                className="preview-section"
                                style={{
                                    flex: '0 0 250px',
                                    backgroundColor: 'white',
                                    height: 'auto',
                                    
                                    minHeight: '500px',
                                    padding: '0',
                                    borderRadius: '10px',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                    position: 'sticky',
                                    top: '20px'
                                }}>
                                <div className=""
                                    style={{ padding: '10px' }}>
                                    <h3>Preview</h3>
                                    <div style={{
                                        height: '350px',
                                        marginBottom: '20px',
                                        border: '1px solid #ddd',
                                        borderRadius: '8px',
                                        overflow: 'hidden',
                                        position: 'relative'
                                    }}>
                                        {selectedprodcts.length < 6 && (
                                            <div style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                right: 0,
                                                bottom: 0,
                                                backgroundColor: 'rgb(255, 255, 255)',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                zIndex: 10,
                                                padding: '10px',
                                                textAlign: 'center'
                                            }}>
                                                <img
                                                    src="images/wishlist default.svg"
                                                    alt="Select products"
                                                    style={{
                                                        width: '80px',
                                                        marginBottom: '15px'
                                                    }}
                                                />
                                                <p style={{
                                                    color: '#666',
                                                    fontSize: '14px',
                                                    margin: 0
                                                }}>
                                                    Please select 6 products from wishlist to preview your look
                                                </p>
                                                <p style={{
                                                    color: '#999',
                                                    fontSize: '12px',
                                                    margin: '5px 0 0 0'
                                                }}>
                                                    {6 - selectedprodcts.length} more {6 - selectedprodcts.length === 1 ? 'product' : 'products'} needed
                                                </p>
                                            </div>
                                        )}
                                        {/* Layout Preview Section */}
                                        <div className="select_layout_box select_layout">
                                            {layout === "4-4" ? (
                                                <div className="classic_img1 select_layouts_bg itemcontent" style={{ background: `url(${bg})`, height: '100%', backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                                                    <div style={{

                                                        height: '100%',

                                                        padding: '24px'
                                                    }}>
                                                        <div style={{
                                                            height: '100%',
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            justifyContent: 'space-between'
                                                        }}>
                                                            {/* First row - 2 images */}
                                                            <div style={{
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                height: '48%'
                                                            }}>
                                                                {selectedprodcts.length >= 1 && (
                                                                    <div style={{
                                                                        width: '48%',
                                                                        height: '100%',
                                                                        overflow: 'hidden',
                                                                        border: '2px solid white',
                                                                        borderRadius: '6px'
                                                                    }}>
                                                                        <LazyLoadImage
                                                                            src={selectedprodcts[0]?.productimage}
                                                                            alt="Product"
                                                                            style={{
                                                                                width: '100%',
                                                                                height: '100%',
                                                                                objectFit: 'cover'
                                                                            }}
                                                                        />
                                                                    </div>
                                                                )}
                                                                {selectedprodcts.length >= 2 && (
                                                                    <div style={{
                                                                        width: '48%',
                                                                        height: '100%',
                                                                        overflow: 'hidden',
                                                                        border: '2px solid white',
                                                                        borderRadius: '6px'
                                                                    }}>
                                                                        <LazyLoadImage
                                                                            src={selectedprodcts[1]?.productimage}
                                                                            alt="Product"
                                                                            style={{
                                                                                width: '100%',
                                                                                height: '100%',
                                                                                objectFit: 'cover'
                                                                            }}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>

                                                            {/* Second row - 2 images */}
                                                            <div style={{
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                height: '48%'
                                                            }}>
                                                                {selectedprodcts.length >= 3 && (
                                                                    <div style={{
                                                                        width: '48%',
                                                                        height: '100%',
                                                                        overflow: 'hidden',
                                                                        border: '2px solid white',
                                                                        borderRadius: '6px'
                                                                    }}>
                                                                        <LazyLoadImage
                                                                            src={selectedprodcts[2]?.productimage}
                                                                            alt="Product"
                                                                            style={{
                                                                                width: '100%',
                                                                                height: '100%',
                                                                                objectFit: 'cover'
                                                                            }}
                                                                        />
                                                                    </div>
                                                                )}
                                                                {selectedprodcts.length >= 4 && (
                                                                    <div style={{
                                                                        width: '48%',
                                                                        height: '100%',
                                                                        overflow: 'hidden',
                                                                        border: '2px solid white',
                                                                        borderRadius: '6px'
                                                                    }}>
                                                                        <LazyLoadImage
                                                                            src={selectedprodcts[3]?.productimage}
                                                                            alt="Product"
                                                                            style={{
                                                                                width: '100%',
                                                                                height: '100%',
                                                                                objectFit: 'cover'
                                                                            }}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : layout === "2-2-1" ? (
                                                <div className="classic_img1 select_layouts_bg itemcontent" style={{ background: `url(${bg})`, height: '80%', backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                                                    <div style={{
                                                        height: '100%',
                                                        display: 'flex',
                                                        padding: '10px',
                                                        flexDirection: 'column',
                                                        justifyContent: 'space-between'
                                                    }}>
                                                        {/* First row - 2 images */}
                                                        <div style={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            height: '30%',
                                                            width: '80%',
                                                            gap: '60px',
                                                            margin: '5px auto' // Centers the container itself
                                                        }}>
                                                            {selectedprodcts.length >= 1 && (
                                                                <div style={{
                                                                    width: '30%',
                                                                    height: '100%',
                                                                    overflow: 'hidden',
                                                                    border: '2px solid white',
                                                                    borderRadius: '6px',
                                                                    marginRight: 'auto' // Pushes the first image to the left
                                                                }}>
                                                                    <LazyLoadImage
                                                                        src={selectedprodcts[0]?.productimage}
                                                                        alt="Product"
                                                                        style={{
                                                                            width: '100%',
                                                                            height: '100%',
                                                                            objectFit: 'cover'
                                                                        }}
                                                                    />
                                                                </div>
                                                            )}
                                                            {selectedprodcts.length >= 2 && (
                                                                <div style={{
                                                                    width: '30%',
                                                                    height: '100%',
                                                                    overflow: 'hidden',
                                                                    border: '2px solid white',
                                                                    borderRadius: '6px',
                                                                    marginLeft: 'auto' // Pushes the second image to the right
                                                                }}>
                                                                    <LazyLoadImage
                                                                        src={selectedprodcts[1]?.productimage}
                                                                        alt="Product"
                                                                        style={{
                                                                            width: '100%',
                                                                            height: '100%',
                                                                            objectFit: 'cover'
                                                                        }}
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>

                                                        {/* Second row - 1 image */}
                                                        {selectedprodcts.length >= 3 && (
                                                            <div style={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                height: '30%',

                                                                marginBottom: '5px',
                                                            }}>
                                                                <div style={{
                                                                    width: '30%',
                                                                    height: '100%',
                                                                    overflow: 'hidden',
                                                                    border: '2px solid white',
                                                                    borderRadius: '6px'
                                                                }}>
                                                                    <LazyLoadImage
                                                                        src={selectedprodcts[2]?.productimage}
                                                                        alt="Product"
                                                                        style={{
                                                                            width: '100%',
                                                                            height: '100%',
                                                                            objectFit: 'cover'
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}

                                                        {/* Third row - last 2 images */}
                                                        <div style={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            height: '30%',
                                                            width: '90%',
                                                            gap: '60px',
                                                            margin: '5px auto'
                                                        }}>
                                                            {selectedprodcts.length >= 4 && (
                                                                <div style={{
                                                                    width: '30%',
                                                                    height: '100%',
                                                                    overflow: 'hidden',
                                                                    border: '2px solid white',
                                                                    borderRadius: '6px'
                                                                }}>
                                                                    <LazyLoadImage
                                                                        src={selectedprodcts[3]?.productimage}
                                                                        alt="Product"
                                                                        style={{
                                                                            width: '100%',
                                                                            height: '100%',
                                                                            objectFit: 'cover'
                                                                        }}
                                                                    />
                                                                </div>
                                                            )}
                                                            {selectedprodcts.length >= 6 && (
                                                                <div style={{
                                                                    width: '30%',
                                                                    height: '100%',
                                                                    overflow: 'hidden',
                                                                    border: '2px solid white',
                                                                    borderRadius: '6px'
                                                                }}>
                                                                    <LazyLoadImage
                                                                        src={selectedprodcts[4]?.productimage}
                                                                        alt="Product"
                                                                        style={{
                                                                            width: '100%',
                                                                            height: '100%',
                                                                            objectFit: 'cover'
                                                                        }}
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : layout === "3-2" ? (

                                                <div className="classic_img1 select_layouts_bg itemcontent" style={{ background: `url(${bg})`, height: '100%', backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                                                    <div className="classic_img1 new-look-box-section">
                                                        <div className="row_section_layout2 row-section">
                                                            <div className="classic_section_box2 section">
                                                                {selectedprodcts.length >= 1 && (
                                                                    <div style={{

                                                                        height: '90%',

                                                                        padding: '10px'
                                                                    }}>
                                                                        <div style={{
                                                                            height: '40%',
                                                                            display: 'flex',
                                                                            flexDirection: 'column',
                                                                            gap: '10px',
                                                                        }}>
                                                                            {/* First row - 2 images */}
                                                                            <div style={{
                                                                                display: 'flex',
                                                                                justifyContent: 'space-between',
                                                                                height: '60%',
                                                                            }}>
                                                                                {selectedprodcts.length >= 1 && (
                                                                                    <div style={{
                                                                                        width: '30%',
                                                                                        height: '100%',
                                                                                        overflow: 'hidden',
                                                                                        border: '2px solid white',
                                                                                        borderRadius: '6px'
                                                                                    }}>
                                                                                        <LazyLoadImage
                                                                                            src={selectedprodcts[0]?.productimage}
                                                                                            alt="Product"
                                                                                            style={{
                                                                                                width: '100%',
                                                                                                height: '100%',
                                                                                                objectFit: 'cover'
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                                {selectedprodcts.length >= 2 && (
                                                                                    <div style={{
                                                                                        width: '30%',
                                                                                        height: '100%',
                                                                                        overflow: 'hidden',
                                                                                        border: '2px solid white',
                                                                                        borderRadius: '6px'
                                                                                    }}>
                                                                                        <LazyLoadImage
                                                                                            src={selectedprodcts[1]?.productimage}
                                                                                            alt="Product"
                                                                                            style={{
                                                                                                width: '100%',
                                                                                                height: '100%',
                                                                                                objectFit: 'cover'
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                            </div>

                                                                            {/* Second row - 2 images */}
                                                                            <div style={{
                                                                                display: 'flex',
                                                                                justifyContent: 'space-between',
                                                                                height: '60%',
                                                                            }}>
                                                                                {selectedprodcts.length >= 3 && (
                                                                                    <div style={{
                                                                                        width: '30%',
                                                                                        height: '100%',
                                                                                        overflow: 'hidden',
                                                                                        border: '2px solid white',
                                                                                        borderRadius: '6px'
                                                                                    }}>
                                                                                        <LazyLoadImage
                                                                                            src={selectedprodcts[2]?.productimage}
                                                                                            alt="Product"
                                                                                            style={{
                                                                                                width: '100%',
                                                                                                height: '100%',
                                                                                                objectFit: 'cover'
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                                {selectedprodcts.length >= 4 && (
                                                                                    <div style={{
                                                                                        width: '30%',
                                                                                        height: '100%',
                                                                                        overflow: 'hidden',
                                                                                        border: '2px solid white',
                                                                                        borderRadius: '6px'
                                                                                    }}>
                                                                                        <LazyLoadImage
                                                                                            src={selectedprodcts[3]?.productimage}
                                                                                            alt="Product"
                                                                                            style={{
                                                                                                width: '100%',
                                                                                                height: '100%',
                                                                                                objectFit: 'cover'
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                            </div>

                                                                            {/* Third row*/}
                                                                            <div style={{
                                                                                display: 'flex',
                                                                                justifyContent: 'space-between',
                                                                                height: '60%',
                                                                            }}>
                                                                                {selectedprodcts.length >= 3 && (
                                                                                    <div style={{
                                                                                        width: '30%',
                                                                                        height: '100%',
                                                                                        overflow: 'hidden',
                                                                                        border: '2px solid white',
                                                                                        borderRadius: '6px'
                                                                                    }}>
                                                                                        <LazyLoadImage
                                                                                            src={selectedprodcts[4]?.productimage}
                                                                                            alt="Product"
                                                                                            style={{
                                                                                                width: '100%',
                                                                                                height: '100%',
                                                                                                objectFit: 'cover'
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                                {selectedprodcts.length >= 4 && (
                                                                                    <div style={{
                                                                                        width: '30%',
                                                                                        height: '100%',
                                                                                        overflow: 'hidden',
                                                                                        border: '2px solid white',
                                                                                        borderRadius: '6px'
                                                                                    }}>
                                                                                        <LazyLoadImage
                                                                                            src={selectedprodcts[5]?.productimage}
                                                                                            alt="Product"
                                                                                            style={{
                                                                                                width: '100%',
                                                                                                height: '100%',
                                                                                                objectFit: 'cover'
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className="look_name_section">
                                        <input
                                            type="text"
                                            placeholder="Look name"
                                            onChange={namevalueChange}
                                            value={lookname}
                                            className="look_input_section"
                                            style={{
                                                width: '100%',
                                                padding: '8px',
                                                marginBottom: '10px',
                                                borderRadius: '4px',
                                                border: '1px solid #ddd'
                                            }}
                                        />
                                        <button
                                            className="share_look_btn btn btn-color"
                                            onClick={() => { id !== undefined ? edit_look() : create_look() }}
                                            style={{
                                                width: '100%',
                                                padding: '10px',
                                                backgroundColor: '#FFCA37',
                                                color: 'black',
                                                border: 'none',
                                                borderRadius: '4px',
                                                cursor: 'pointer',
                                                fontWeight: 'bold'
                                            }}
                                        >
                                            Save Look
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <style>{scrollbarStyles}</style>

        </>
    );
}

export default CreateLook;

