/* eslint-disable */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get_friend_list, un_friend } from "../../store/friend/api"
import { Link, useNavigate } from 'react-router-dom';
import { get_friend_list_success } from '../../store/account/accountActions'
const FriendSideBar = () => {
    const history = useNavigate();
    const dispatch = useDispatch();
    // const [friendlist, setFriendList] = useState([]);
    const state_account = useSelector(state => state.account);
    const state_friend = useSelector(state => state.friend);

    useEffect(() => {
        let isMounted = true;
        if (isMounted) getfriendlist();
        return () => { isMounted = false };
    }, []);

    const routetotimeline = (i) => {
        history('/timeline', { state: { friendemail: i } });
    }
    const getfriendlist = () => {
        let userpayload =
        {
            "useremail": state_account.profile.email,
            "token": state_account.profile.token,
        }
        dispatch(get_friend_list(userpayload, (res) => {
            if (res.status === 200) {
                // setFriendList(res.data);
                dispatch(get_friend_list_success(res.data))
            }
        }));
    }
    const unfriend = (i) => {
        let payload = {
            "useremail": state_account.profile.email,
            "token": state_account.profile.token,
            "friendemail": i
        }
        dispatch(un_friend(payload, (res) => {
            if (res.status === 200) {
                getfriendlist();
            }
        }));
    }
    return (
        <div className="col-md-3">
            <div className="related_product">
                <p className="related_product_heading text_left"> </p>
                <div className="classic_container no_margin profile_right_sidebar">
                    <div className="classic_height">
                        {
                            state_friend.friendlist.length === 0 ?
                                <>

                                    <div className="request-img">
                                        <img src={"/images/onboarding/friend.svg"} alt=""/>
                                    </div>
                                    <div className="request-section">
                                        <p>Your friend list is empty Search and add some friends to your friend list</p>
                                    </div>
                                    <div className="explore-btn">
                                        <Link to={"/profile/friends"} className="save_btn-new-look ui-link">Search friends</Link>
                                    </div>
                                </> :
                                state_friend.friendlist.map((i, key) => (
                                    <div className="profile_div display_flex space_between2 align_item_center" key={key} >
                                        <div className="display_flex align_item_center">
                                            <div className="name_img_area" onClick={() => { routetotimeline(i.friendemail[0]) }}>
                                                <img src={`${i.profileimage[0] && (i?.provider[0] === "Phonelogin"?/\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(i.profileimage[0]): {})
                                                    ? i.profileimage[0]
                                                    : i.gender === undefined ? "/images/onboarding/man.png" : i.gender === "Men" ? "/images/onboarding/man.png" : "/images/onboarding/woman.png"
                                                    }`} alt="" className="name_img" />
                                                {/* <img src={i.profileimage[0] == "" ? "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y" : `${i.profileimage[0]}`} alt="" className="name_img" /> */}
                                            </div>
                                            <div className="name_content">
                                                <p className="text_bold_600">{i.friendfirstname[0] === "" && i.friendlastname[0] === "" ? "Undefined" : `${i.friendfirstname[0]} ${i.friendlastname[0]}`}</p>
                                            </div>
                                        </div>
                                        <div className="three_dots"> <a data-toggle="modal" data-target={`#unfriendModal${key}`} href="#"><i className="fa-solid fa-ellipsis"></i></a> </div>
                                        <div className="modal fade" id={`unfriendModal${key}`} tabIndex="10" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div className="feed_details modal-dialog offer_dialog modal-md">
                                                <div className="modal-body">
                                                    <div className="friends-timeline-pop-up">
                                                        <div className="stash-pop-up-section">'
                                                            <div className="stash-pop-up-box">
                                                                <div className="wishlist-Just-checking-section">
                                                                    <h6>UNFRIEND</h6>
                                                                </div>
                                                                <div className="delete-section">
                                                                    <h6>Are you sure you want to unfriend?</h6>
                                                                </div>
                                                                <div className="checking-row">
                                                                    <button className="checkingforyes-btn-default" onClick={() => { unfriend(i.friendemail[0]); }} data-dismiss="modal">YES</button>
                                                                    <button className="checking-btn-default" data-dismiss="modal">NO</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>)
                                )
                        }

                    </div>

                </div>
            </div>
        </div>
    );
}
export default FriendSideBar;